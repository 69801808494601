import React, { useEffect, useState } from "react";
import { Button,  Skeleton, Space, Table } from "antd";
import { NameOf, NotificationUtil, TableColumnBuilder } from "src/utils";
import AdminDataController from "src/api/AdminDataController";
import { ColumnProps } from "antd/lib/table";
import AdminCityRequest from "src/models/generated/AdminCityRequest";
import AdminCountryRequest from "src/models/generated/AdminCountryRequest";
import AdminCountryCityDTO from "src/models/generated/AdminCountryCityDTO";
import AdminDataCountryModal from "./AdminDataCountryModal";
import AdminDataCityModal from "./AdminDataCityModal";

const AdminDataCountryCityList: React.FC = () => {
  const [tableData, setTableData] = useState<AdminCountryCityDTO[]>([]);
  const [countries, setCountries] = useState<AdminCountryCityDTO[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<AdminCountryRequest>();
  const [selectedCity, setSelectedCity] = useState<AdminCityRequest>();
  const [countryModelOpen, setCountryModelOpen] = useState(false);
  const [cityModalOpen, setCityModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const loadTable = async () => {
    setLoading(true);

    try {
      // This will get all cities and countries, then we can split the countries out from there
      const cityResult = await AdminDataController.getCities();
      const countryResult = await AdminDataController.getCountries();

      setTableData(cityResult.data);
      setCountries(countryResult.data);
    } catch (error) {
      NotificationUtil.error({
        key: "AdminDataCountryCityList",
        message: "Cities failed to load",
        description: "Error while loading cities. Please refresh the page and try again",
      });
    }

    setLoading(false);
  };

  const handleCountryModalShow = (item?: AdminCountryRequest) => {
    setSelectedCountry(item);
    setCountryModelOpen(true);
  };

  const handleCityModalShow = (item?: AdminCityRequest) => {
    setSelectedCity(item);
    setCityModalOpen(true);
  };

  const handleCountryModalCancel = () => {
    setCountryModelOpen(false);
    setSelectedCountry(undefined);
  };

  const handleCityModalCancel = () => {
    setCityModalOpen(false);
    setSelectedCity(undefined);
  };

  const handleDeleteClick = async (item: AdminCountryCityDTO) => {
    setLoading(true);

    try {
      await AdminDataController.deleteService(item.id!);
      NotificationUtil.success({
        key: "AdminDataCountryCityList",
        message: "City Deleted",
        description: "City was successfully deleted",
      });
    } catch (error) {
      NotificationUtil.error({
        key: "AdminDataCountryCityList",
        message: "City Deletion Failed",
        description: "Error while deleting city. Please try again",
        error
      });
    }

    setLoading(false);
    loadTable();
  };

  const handleCountryModalFinished = async (value: AdminCountryRequest) => {
    setSubmitting(true);

    // We can add or update from the modal, so we need to check the id to determine which
    if (value.id == null) {
      try {
        await AdminDataController.createCountry(value);
        NotificationUtil.success({
          key: "AdminDataCountryCityList",
          message: "Country Created",
          description: "Country was successfully created",
        });
      } catch (error) {
        NotificationUtil.error({
          key: "AdminDataCountryCityList",
          message: "Country Creation Failed",
          description: "Error while creating country. Please try again",
          error
        });
      }
    } else {
      try {
        await AdminDataController.updateCountry(value);
        NotificationUtil.success({
          key: "AdminDataCountryCityList",
          message: "Country Updated",
          description: "Country was successfully updated",
        });
      } catch (error) {
        NotificationUtil.error({
          key: "AdminDataCountryCityList",
          message: "Country Update Failed",
          description: "Error while updating country. Please try again",
          error
        });
      }
    }

    setSubmitting(false);
    setCountryModelOpen(false);
    loadTable();
  };

  const handleCityModalFinished = async (value: AdminCityRequest) => {
    setSubmitting(true);

    // We can add or update from the modal, so we need to check the id to determine which
    if (value.id == null) {
      try {
        await AdminDataController.createCity(value);
        NotificationUtil.success({
          key: "AdminDataCountryCityList",
          message: "City Created",
          description: "City was successfully created",
        });
      } catch (error) {
        NotificationUtil.error({
          key: "AdminDataCountryCityList",
          message: "City Creation Failed",
          description: "Error while creating city. Please try again",
          error
        });
      }
    } else {
      try {
        await AdminDataController.updateCity(value);
        NotificationUtil.success({
          key: "AdminDataCountryCityList",
          message: "City Updated",
          description: "City was successfully updated",
        });
      } catch (error) {
        NotificationUtil.error({
          key: "AdminDataCountryCityList",
          message: "City Update Failed",
          description: "Error while updating city. Please try again",
          error
        });
      }
    }

    setSubmitting(false);
    setCityModalOpen(false);
    loadTable();
  };

  useEffect(() => {
    loadTable();
  }, []);

  // Okay, so we do want the city name, country name and... that's it?
  // Probably should include the isApproved flag as well, not sure how to handle the country approved though...
  const cityTableColumns: ColumnProps<AdminCountryCityDTO>[] = [
    TableColumnBuilder.Create<AdminCountryCityDTO>("name", "Name")
      .Width(140)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminCountryCityDTO>("countryName", "Country Name")
      .Width(140)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminCountryCityDTO>("isCityApproved", "Approved?")
      .Width(100)
      .AddSorter("Boolean")
      .AddRenderer("Boolean", "YesNo", true)
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminCountryCityDTO>("isCountryApproved", "Country Approved?")
      .Width(140)
      .AddSorter("Boolean")
      .AddRenderer("Boolean", "YesNo", true)
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminCountryCityDTO>()
      .Key("Edit")
      .Width(50)
      // .AddRenderer('Custom', (_, record) => (<Button type='link' icon={<EditOutlined />} onClick={() => handleEditClick(record)} />))
      .AddRenderer("Custom", (value, record) => {
        return <Button type="primary" onClick={() => handleCityModalShow(AdminCityRequest.create({ id: record.id, name: record.name, countryId: record.countryId, isAdminApproved: record.isCityApproved }))}>Edit</Button>;
      })
      .Build(),
    TableColumnBuilder.Create<AdminCountryCityDTO>()
      .Key("Delete")
      .Width(50)
      .AddRenderer("Custom", (value, record) => {
        return <Button type="primary" danger onClick={() => handleDeleteClick(record)}>Delete</Button>;
      })
      .Build(),
  ];

  const countryTableColumns: ColumnProps<AdminCountryCityDTO>[] = [
    TableColumnBuilder.Create<AdminCountryCityDTO>("countryName", "Name")
      .Width(140)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminCountryCityDTO>("isCountryApproved", "Approved?")
      .Width(100)
      .AddSorter("Boolean")
      .AddRenderer("Boolean", "YesNo", true)
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminCountryCityDTO>()
      .Key("Edit")
      .Width(50)
      .AddRenderer("Custom", (value, record) => {
        return <Button type="primary" onClick={() => handleCountryModalShow(AdminCountryRequest.create({ id: record.countryId, name: record.countryName, isAdminApproved: record.isCountryApproved }))}>Edit</Button>;
      })
      .Build(),
  ];

  return (
    <div className="admin-users-page">
      <h1>Country / City List Page</h1>
      <p>
        Lists the available Countries and Cities available for users to select. This affects &lsquo;Current
        Address&rsquo; and the Homepage
      </p>

      <Skeleton
        active
        loading={loading}
      >
        <Space>
          <Button
            type="primary"
            onClick={() => handleCountryModalShow()}
          >
            Add New Country
          </Button>
          <Button
            type="primary"
            onClick={() => handleCityModalShow()}
          >
            Add New City
          </Button>
        </Space>

        <h2 style={{ marginTop: 16 }}>Cities</h2>
        <Table
          size="small"
          style={{ marginTop: 12, maxWidth: 1200 }}
          rowKey={NameOf<AdminCountryCityDTO>("id")}
          className="striped-table "
          rowClassName={(record, index) => (index % 2 ? "striped-row" : "")}
          pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
          loading={loading}
          columns={cityTableColumns}
          dataSource={tableData}
        />

        <h2 style={{ marginTop: 16 }}>Countries</h2>
        <Table
          size="small"
          style={{ marginTop: 12, maxWidth: 1200 }}
          rowKey={NameOf<AdminCountryCityDTO>("id")}
          className="striped-table "
          rowClassName={(record, index) => (index % 2 ? "striped-row" : "")}
          pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
          loading={loading}
          columns={countryTableColumns}
          dataSource={countries}
        />
      </Skeleton>

      <AdminDataCountryModal
        open={countryModelOpen}
        value={selectedCountry}
        loading={submitting}
        onCancel={handleCountryModalCancel}
        onFinished={handleCountryModalFinished}
      />

      <AdminDataCityModal
        open={cityModalOpen}
        value={selectedCity}
        loading={submitting}
        onCancel={handleCityModalCancel}
        onFinished={handleCityModalFinished}
        countries={countries}
      />
    </div>
  );
};

export default AdminDataCountryCityList;
