import { Menu, MenuProps, Layout } from "antd";
import React from "react";
import "./HomeHeader.less";

interface HomeHeaderProps {
  menuItems: MenuProps["items"];
}

const { Header } = Layout;

const HomeHeader: React.FC<React.PropsWithChildren<HomeHeaderProps>> = (props) => {
  return (
    <Header className='home-header'>
      <Menu
        mode="horizontal"
        items={props.menuItems}
      />
    </Header>
  );
};

export default HomeHeader;
