import { Grid, Layout, Menu, MenuProps } from "antd";
import React, { useContext, useState } from "react";
import StandardHeader from "./headers/StandardHeader";
import StandardFooter from "./footers/StandardFooter";
import "./PublicLayout.less";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import { NavLink } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const PublicLayout: React.FC<React.PropsWithChildren> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const breakpoint = useBreakpoint();

  const isDesktop = breakpoint.lg || breakpoint.xl || breakpoint.xxl;
  const isMobile = breakpoint.xs || breakpoint.sm || breakpoint.md;

  const menuItems: MenuProps["items"] = [
    { key: "1", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Home</NavLink> },
    { key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.SERVICES }}>Services</NavLink> },
    { key: "3", label: <NavLink to={{ pathname: RouteConfig.LINK.ON_DEMAND }}>On-Demand</NavLink> },
    { key: "4", label: <NavLink to={{ pathname: RouteConfig.LINK.ABOUT_US }}>About Us</NavLink> },
    { key: "5", label: <NavLink to={{ pathname: RouteConfig.LINK.CONTACT_US }}>Contact Us</NavLink> },
  ];

  // Add either login or profile depending on if the user is logged in or not
  if (authContext.isLoggedIn) {
    menuItems.push({ key: "6", label: <NavLink to={{ pathname: RouteConfig.LINK.PROFILE_EDIT }}>Profile</NavLink> });
  } else if (isDesktop) {
    // We want to keep the desktop version somewhat clean as the mobile version has a drawer
    menuItems.push({ key: "7", label: <NavLink to={{ pathname: RouteConfig.LINK.LOGIN }}>Log In</NavLink> });
  } else {
    menuItems.push({ key: "8", type: "divider" });
    menuItems.push({ key: "9", label: <NavLink to={{ pathname: RouteConfig.LINK.LOGIN }}>Log In</NavLink> });
    menuItems.push({ key: "10", label: <NavLink to={{ pathname: RouteConfig.LINK.REGISTER }}>Sign Up</NavLink> });
  }

  return (
    <Layout className='public-layout'>
      <Content className='expandable-container'>
        <StandardHeader header="public" menuItems={menuItems} />

        {/* This is the best I could do for a sensible centered experience */}
        <Content className="flex-container">
          <Content className='content-container'>
            {props.children}
          </Content>
        </Content>
      </Content>
      <StandardFooter />
    </Layout>
  );
};

export default PublicLayout;

// Values for later
/**
 * width of the site: 1450 or so
 * real site is about 1200 with logo and all that
 *
 */