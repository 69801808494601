/** Provides the nameof functionality from C# with the ease of use of Typescript */
function NameOf<T>(key: keyof T): keyof T;
function NameOf<T>(...keys: (keyof T)[]): (keyof T)[];
function NameOf<T>(...keys: (keyof T)[]): keyof T | (keyof T)[] {
  if (keys.length === 1) {
    return keys[0];
  }
  return keys;
}

export default NameOf;

// Dev Note: This is a warning to any future dev, likely me, that wishes to go down this route.
// It is very simple. You will not find a way to upgrade NameOf to work with nested properties. It's just not going to work.
// I've tried, sunk many many hours into it with nothing to show for my time. I wish I could go back in time and tell myself to just not, but here we are.
// So please, just don't. ~JB (Jan 2024)
