import React, { useEffect, useState } from "react";
import { Button,  Skeleton, Table } from "antd";
import { NameOf, NotificationUtil, TableColumnBuilder } from "src/utils";
import AdminDataController from "src/api/AdminDataController";
import ServiceDTO from "src/models/generated/ServiceDTO";
import { ColumnProps } from "antd/lib/table";
import AdminDataServiceModal from "./AdminDataServiceModal";
import AdminUpdateServiceRequest from "src/models/generated/AdminUpdateServiceRequest";

const AdminDataServiceList: React.FC = () => {
  const [tableData, setTableData] = useState<ServiceDTO[]>([]);
  const [selectedItem, setSelectedItem] = useState<AdminUpdateServiceRequest>();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const loadTable = async () => {
    setLoading(true);

    try {
      const result = await AdminDataController.getServices();
      setTableData(result.data);
    } catch (error) {
      NotificationUtil.error({
        key: "AdminDataServiceList",
        message: "Services failed to load",
        description: "Error while loading services. Please refresh the page and try again",
      });
    }

    setLoading(false);
  };

  const handleModalShow = (item?: AdminUpdateServiceRequest) => {
    setModalOpen(true);
    setSelectedItem(item);
  };

  const handleModalCancel = () => {
    setModalOpen(false);
    setSelectedItem(undefined);
  };

  const handleDeleteClick = async (item: ServiceDTO) => {
    setLoading(true);

    try {
      await AdminDataController.deleteService(item.id!);
      NotificationUtil.success({
        key: "AdminDataServiceList",
        message: "Service Deleted",
        description: "Service was successfully deleted",
      });
    } catch (error) {
      NotificationUtil.error({
        key: "AdminDataServiceList",
        message: "Service Deletion Failed",
        description: "Error while deleting service. Please try again",
        error
      });
    }

    setLoading(false);
    loadTable();
  };

  const handleModalFinished = async (value: AdminUpdateServiceRequest) => {
    setSubmitting(true);

    // We can add or update from the modal, so we need to check the id to determine which
    if (value.id == null) {
      try {
        await AdminDataController.createService(value);
        NotificationUtil.success({
          key: "AdminDataServiceList",
          message: "Service Created",
          description: "Service was successfully created",
        });
      } catch (error) {
        NotificationUtil.error({
          key: "AdminDataServiceList",
          message: "Service Creation Failed",
          description: "Error while creating service. Please try again",
          error
        });
      }
    } else {
      try {
        await AdminDataController.updateService(value);
        NotificationUtil.success({
          key: "AdminDataServiceList",
          message: "Service Updated",
          description: "Service was successfully updated",
        });
      } catch (error) {
        NotificationUtil.error({
          key: "AdminDataServiceList",
          message: "Service Update Failed",
          description: "Error while updating service. Please try again",
          error
        });
      }
    }

    setSubmitting(false);
    setModalOpen(false);
    loadTable();
  };

  useEffect(() => {
    loadTable();
  }, []);

  const tableColumns: ColumnProps<ServiceDTO>[] = [
    TableColumnBuilder.Create<ServiceDTO>("id", "Id")
      .Width(70)
      .AddSorter("Number")
      .AddRenderer("Text")
      .AddTextFilterer()
      .DefaultSortOrder("ascend")
      .Build(),
    TableColumnBuilder.Create<ServiceDTO>("name", "Name")
      .Width(140)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<ServiceDTO>("deliveryType", "Delivery Type")
      .Width(150)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<ServiceDTO>("defaultPrice", "Default Price")
      .Width(140)
      .AddSorter("Number")
      .AddRenderer("Currency")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<ServiceDTO>("description", "Description")
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<ServiceDTO>()
      .Key("Edit")
      .Width(50)
      // .AddRenderer('Custom', (_, record) => (<Button type='link' icon={<EditOutlined />} onClick={() => handleEditClick(record)} />))
      .AddRenderer("Custom", (value, record) => {
        return <Button type="primary" onClick={() => handleModalShow(AdminUpdateServiceRequest.create(record))}>Edit</Button>;
      })
      .Build(),
    TableColumnBuilder.Create<ServiceDTO>()
      .Key("Delete")
      .Width(50)
      .AddRenderer("Custom", (value, record) => {
        return <Button type="primary" danger onClick={() => handleDeleteClick(record)}>Delete</Button>;
      })
      .Build(),
  ];

  return (
    <div className='admin-users-page'>
      <h1>Services List Page</h1>
      <p>Lists the available services that interpreters can offer.</p>
      <Button type='primary' onClick={() => handleModalShow()}>Add New Service</Button>

      <Skeleton active loading={loading}>
        <Table
          size="small"
          style={{ marginTop: 12, maxWidth: 1200 }}
          rowKey={NameOf<ServiceDTO>("id")}
          className='striped-table '
          rowClassName={(record, index) => (index % 2 ? "striped-row" : "")}
          pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />
      </Skeleton>

      <AdminDataServiceModal
        open={modalOpen}
        value={selectedItem}
        loading={submitting}
        onCancel={handleModalCancel}
        onFinished={handleModalFinished}
      />
    </div>
  );
};

export default AdminDataServiceList;
