import React from "react";
import { Grid, MenuProps } from "antd";
import MobileHeader from "./MobileHeader";
import HomeHeader from "./HomeHeader";
import PublicHeader from "./PublicHeader";
import UserHeader from "./UserHeader";

const { useBreakpoint } = Grid;

interface StandardHeaderProps {
  menuItems: MenuProps["items"];
  header?: "home" | "public" | "user";
  mobileHeader?: "mobile";
}

const StandardHeader: React.FC<React.PropsWithChildren<StandardHeaderProps>> = ({ header = "public", mobileHeader = "mobile", menuItems, children }) => {
  const breakpoint = useBreakpoint();

  const isDesktop = breakpoint.lg || breakpoint.xl || breakpoint.xxl;
  const isMobile = breakpoint.xs || breakpoint.sm || breakpoint.md;

  if (isMobile && !isDesktop) {
    // Currently, we only support the mobile header ~nya
    return <MobileHeader menuItems={menuItems}>{children}</MobileHeader>;
  }

  switch (header) {
    case "home":
      return <HomeHeader menuItems={menuItems}>{children}</HomeHeader>;
    case "public":
      return <PublicHeader menuItems={menuItems}>{children}</PublicHeader>;
    case "user":
      return <UserHeader menuItems={menuItems}>{children}</UserHeader>;
    default:
      return <PublicHeader menuItems={menuItems}>{children}</PublicHeader>;
  }
};

export default StandardHeader;
