import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Skeleton } from "antd";
import NotificationUtil from "src/utils/NotificationUtil";
import UserProfileDTO from "src/models/generated/UserProfileDTO";
// import { DayPilot, DayPilotCalendar } from "@daypilot/daypilot-lite-react";
import { DayPilot, DayPilotCalendar } from "daypilot-pro-react";
import moment from "moment-timezone";
import TimeIntervalDTO from "src/models/generated/TimeIntervalDTO";
import AccountController from "src/api/AccountController";
import WorkingHoursRequest from "src/models/generated/WorkingHoursRequest";
import { NameOf } from "src/utils";

interface ScheduleTabFormData extends UserProfileDTO {}

interface ScheduleTabProps {}

// Dev Note: This tab is rather complicated, so it will handle it's own loading and saving

const ScheduleTab: React.FC<ScheduleTabProps> = (props) => {
  // Dev Note: Use this to get the form since we are child of the tabs
  const form = Form.useFormInstance<ScheduleTabFormData>();

  // const calendarRef = createRef<DayPilotCalendar>();
  const calendarRef = useRef<DayPilotCalendar>();
  const [calendarEvents, setCalendarEvents] = useState<DayPilot.EventData[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const updateCalendar = (blocks: TimeIntervalDTO[]) => {
    // Ensure that the results are in the current timezone
    const dayPilotEvents = blocks.map((x, i) => {
      return {
        id: i,
        start: new DayPilot.Date(x.start!.local().toDate(), true),
        end: new DayPilot.Date(x.end!.local().toDate(), true),
        text: "Available",
      };
    });

    // Persist the events in state
    setCalendarEvents(dayPilotEvents);
  };

  const handleEventChanged = (args: any) => {
    // The goal of this method has changed and will be to try and push the local changes to the outer form
    const blocks = calendarEvents.map((x: any) => {
      return TimeIntervalDTO.create({
        start: moment(x.start.toString()),
        end: moment(x.end.toString()),
      });
    });
    const timeZoneOffsetInMinutes = -(new Date().getTimezoneOffset());

    const request = WorkingHoursRequest.create({ blocks, timeZoneOffsetInMinutes });

    form.setFieldValue(NameOf<UserProfileDTO>("workingHours"), request);
  };

  const handleFinish = async () => {
    setSubmitting(true);
    try {
      // convert the calendar events to blocks
      const blocks = calendarEvents.map((x: any) => {
        return TimeIntervalDTO.create({
          start: moment(x.start.toString()),
          end: moment(x.end.toString()),
        });
      });
      const timeZoneOffsetInMinutes = -(new Date().getTimezoneOffset());

      const request = WorkingHoursRequest.create({ blocks, timeZoneOffsetInMinutes });
      const result = await AccountController.updateCurrentUserProfileWorkingHours(request);
      updateCalendar(result.data);
      NotificationUtil.success({
        key: "ProfileEditScheduleTab",
        message: "Schedule Updated",
        description: "Your schedule has been saved successfully.",
      });
    } catch (error) {
      NotificationUtil.error({
        key: "ProfileEditScheduleTab",
        message: "Error Saving Schedule",
        description: "There was an error saving your schedule. Please try again later.",
        error,
      });
    }
    setSubmitting(false);
  };

  const loadSchedule = async () => {
    // Load up the working hours from the api
    setLoading(true);
    try {
      const results = await AccountController.getCurrentUserProfileWorkingHours(`${-new Date().getTimezoneOffset()}`);
      console.log("Here are the dates I received with timezones", results.data.map(x => ({ start: x.start?.format(), end: x.end?.format() })) );
      updateCalendar(results.data);
    } catch (error) {
      NotificationUtil.error({
        key: "profile-edit-schedule-error",
        message: "Error Loading Schedule",
        description: "There was an error loading your schedule. Please try again later.",
        error,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    loadSchedule();
  }, []);

  return (
    <div>
      <p>
        The schedule allows you to set times when you are available for translating services. Any empty spaces are
        considered &quot;unavailable&quot; and users will not be able to book services during that time. Bookings will
        also not be allowed over gaps except for half-day or full day appointments.
      </p>
      <p><b>Note: Schedule is only saved from the &apos;Save Schedule&apos; button</b></p>
      <Skeleton loading={loading}>
        <Button
          type="primary"
          style={{ padding: "0 20px", marginBottom: 8 }}
          loading={submitting}
          onClick={handleFinish}
        >
          Save Schedule
        </Button>
        <DayPilotCalendar
          ref={calendarRef as any}
          viewType="Week"
          locale="en-us"
          allowEventOverlap={false}
          timeRangeSelectedHandling="Enabled"
          eventDoubleClickHandling="Enabled"
          eventDeleteHandling="Update"
          // Note: DayPilot will update this value directly, which is not what you should do, but what can we do?
          events={calendarEvents}
          onBeforeHeaderRender={(args) => {
            // This formats the header to the day of the week, which is all we need. The convert to saveable time is handled by the api
            args.header.html = moment(args.header.start.toString()).format("dddd");
          }}
          onTimeRangeSelected={(args) => {
            if (args == null) return;
            args.control.clearSelection();
            let newEvent: DayPilot.EventData = {
              id: DayPilot.guid(),
              start: args.start,
              end: args.end,
              text: "Available",
            };

            args.control.events.add(newEvent);
            // handleEventChanged(args);
          }}
          // onEventResized={handleEventChanged}
          // onEventMoved={handleEventChanged}
        />
      </Skeleton>
    </div>
  );
};

export default ScheduleTab;
