import React from "react";
import { Skeleton } from "antd";

const LoadingComponent: React.FC = () => {
  return (<div>
    <h2>Loading, please wait</h2>
    <Skeleton active />
  </div>);
};

export default LoadingComponent;