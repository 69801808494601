import React, { useContext, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthenticationContext } from "../providers/AuthenticationContext";
import Pages from "src/config/PageConfig";
import NotFoundComponent from "src/components/NotFoundComponent";
import LoadingComponent from "src/components/LoadingComponent";
import { AllLayoutMapper, DefaultLayout } from "./PageInterface";

const App: React.FC = (): JSX.Element => {
  const authContext = useContext(AuthenticationContext);

  // Doing this over, for the 3rd time in memory, sigh
  const renderPages = (): JSX.Element[] => {
    const isLoggedIn = authContext.isLoggedIn;
    const userRole = authContext.userRole?.extra ?? "";

    // Dev Note: As for loading: We could just override the NotFoundComponent to be LoadingComponent or similar. This would show a loader if the isLoading on authContext is active
    // Alternatively, we could just... not. The only ones that are affected are the logged in routes

    const mysteryPages = Pages.MyPages.map((page) => {
      if (page.type === "basic") {
        const Layout = AllLayoutMapper[page.layout] ?? DefaultLayout;
        const Component = page.component ?? NotFoundComponent;
        return <Route
          key={page.key}
          path={page.route}
          element={<Layout><Component /></Layout>}
        />;
      } else if (isLoggedIn) {
        let Layout = AllLayoutMapper[page.layout] ?? DefaultLayout;
        let Component = page.roles[userRole];

        // Check if the user is able to visit the route
        if (Component == null) {
          // User is not in route, render the default, fallback to NotFound
          Layout = AllLayoutMapper[page.publicLayout!] ?? DefaultLayout;
          Component = page.component ?? NotFoundComponent;
        }

        return <Route
          key={page.key}
          path={page.route}
          element={<Layout><Component /></Layout>}
        />;
      }

      // This is the route shown when the user is not logged in
      return <Route
        key={page.key}
        path={page.route}
        element={<DefaultLayout><LoadingComponent /></DefaultLayout>}
      />;
    });

    // Add the 404 fallback
    mysteryPages.push(<Route key='404' path='*' element={<DefaultLayout><NotFoundComponent /></DefaultLayout>} />);

    return mysteryPages;
  };

  let renderedPages: JSX.Element[] = useMemo(renderPages, [authContext.isInitialized, authContext.isLoggedIn, authContext.userRole]);

  // Done rendering pages
  return (
    <Routes key='routes'>
      {renderedPages}
    </Routes>
  );
};

export default App;
