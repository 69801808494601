import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Form, Input, Rate, Select, Space } from "antd";
import { useStateAsync } from "src/hooks";
import { ConstantsUtil } from "src/utils";

export interface LanguageProficiencyValue {
  /** This is a the language code, such as 'en-us' */
  languageCode?: string;
  proficiencyId?: number;
}

export interface LanguageProficiencySelectorProps {
  disabled?: boolean;
  hideProficiencyLabel?: boolean;

  // Props pulled from RangePicker
  // Dev Note: This is just SO hard to implement, so we are not
  // defaultValue?: LanguageProficiencyValue;
  value?: LanguageProficiencyValue;
  onChange?: (value: LanguageProficiencyValue) => void;
}

const LanguageProficiencySelector: React.FC<LanguageProficiencySelectorProps> = (props) => {
  const proficiencies = ConstantsUtil.proficiencies;
  const languages = ConstantsUtil.languages;

  const [languageCode, setLanguageCode] = useState<string | undefined>();
  const [proficiencyValue, setProficiencyValue] = useState<number | undefined>();

  useEffect(() => {
    if (props.value == null) {
      return;
    }
    setLanguageCode(props.value.languageCode);
    setProficiencyValue(proficiencies.find(x => x.key === props.value!.proficiencyId)?.order);
  }, [props.value]);

  const handleLanguageChange = (languageCode: string) => {
    setLanguageCode(languageCode);
    props.onChange?.({ languageCode, proficiencyId: Number(proficiencies.find(x => x.order === proficiencyValue)?.key) });
  };

  const handleProficiencyChange = (proficiencyValue: number) => {
    // The proficiency value will be 0-5, we need to pass this through the proficiencies collection to get the ID, which is passed up
    setProficiencyValue(proficiencyValue);
    props.onChange?.({ languageCode, proficiencyId: Number(proficiencies.find(x => x.order === proficiencyValue)?.key) });
  };

  return (<Space>
    <Select
      style={{ width: 150 }}
      placeholder="Select Language"
      disabled={props.disabled}
      options={languages}
      value={languageCode}
      onChange={handleLanguageChange}
    />
    <Rate
      allowClear
      count={proficiencies.length - 1}
      value={proficiencyValue}
      onChange={handleProficiencyChange}
    />
    {!props.hideProficiencyLabel && <span className="ant-rate-text">{(proficiencyValue != null && proficiencies.find(x => x.order === proficiencyValue)?.label) ?? "None"}</span>}
  </Space>);
};

export default LanguageProficiencySelector;
