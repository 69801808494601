import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Select, Skeleton, Switch, Tooltip } from "antd";

const CreateAppointmentProcessingPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  return (
    <div>
      <h1>Processing Appointment</h1>
      <span>Just a loader and a 'hang on a moment' blerb</span>
    </div>
  );
};

export default CreateAppointmentProcessingPage;
