import * as React from "react";
import { Avatar, Button, Col, Divider, Dropdown, Grid, Layout, Menu, Row, Space, Typography } from "antd";
import { BarChartOutlined, BookOutlined, FacebookFilled, FacebookOutlined, HomeOutlined, InstagramFilled, LinkedinFilled, LinkedinOutlined, MenuOutlined, PoweroffOutlined, SettingOutlined, TwitterOutlined, TwitterSquareFilled, UserOutlined, UserSwitchOutlined, YoutubeFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import RouteConfig from "../../config/RouteConfig";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import classNames from "classnames";
import "./StandardFooter.less";

const { Footer } = Layout;
const { useBreakpoint } = Grid;

const StandardFooter: React.FC = () => {
  const breakpoint = useBreakpoint();

  const isDesktop = breakpoint.lg || breakpoint.xl || breakpoint.xxl;
  const isMobile = breakpoint.xs || breakpoint.sm || breakpoint.md;

  if (isMobile && !isDesktop) {
    return (
      <Footer className='standard-footer mobile'>
        <Row gutter={[0, 8]}>
          <Col className='icons' span={24}>
            <Space size={20}>
              <YoutubeFilled />
              <InstagramFilled />
              <TwitterOutlined />
              <LinkedinFilled />
              <FacebookFilled />
            </Space>
          </Col>
          <Col className='words' span={24}>
            <Space split={<Divider type="vertical" />}>
              <span>FAQs</span>
              <span>Contact Us</span>
              <span>Terms &amp; Conditions</span>
            </Space>
          </Col>
        </Row>
      </Footer>
    );
  }
  return (
    <Footer className='standard-footer'>
      <Row gutter={[0, 8]}>
        <Col className='icons' span={24}>
          <Space size={20}>
            <YoutubeFilled />
            <InstagramFilled />
            <TwitterOutlined />
            <LinkedinFilled />
            <FacebookFilled />
          </Space>
        </Col>
        <Col className='words' span={24}>
          <Space split={<Divider type="vertical" />}>
            <span>FAQs</span>
            <span>Contact Us</span>
            <span>Terms &amp; Conditions</span>
          </Space>
        </Col>
      </Row>
    </Footer>);
};

export default StandardFooter;
