import React, { useEffect } from "react";
import { Modal, Form, Input, Select, InputNumber, Button } from "antd";
import ServiceDTO from "src/models/generated/ServiceDTO";
import AdminUpdateServiceRequest from "src/models/generated/AdminUpdateServiceRequest";
import { NameOf } from "src/utils";

interface AdminDataServiceModalProps {
  open: boolean;
  value?: ServiceDTO;
  loading?: boolean;
  onCancel: () => void;
  onFinished: (value: AdminUpdateServiceRequest) => void;
}

const AdminDataServiceModal: React.FC<AdminDataServiceModalProps> = (props) => {
  const [form] = Form.useForm();

  const handleFinished = (value: AdminUpdateServiceRequest) => {
    if (props.value != null)
      value = { ...value, id: props.value.id };
    props.onFinished?.(value);
  };

  useEffect(() => {
    form.setFieldsValue(props.value);
  }, [props.value, props.open]);

  return (
    <Modal
      open={props.open}
      title={props.value?.id != null ? "Edit Service" : "Create Service"}
      confirmLoading={props.loading}
      onCancel={props.onCancel}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleFinished}
        labelCol={{ flex: "0 0 110px" }}
      >
        {/* Name */}
        <Form.Item
          label="Name"
          name={NameOf<AdminUpdateServiceRequest>("name")}
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>

        {/* Description */}
        <Form.Item
          label="Description"
          name={NameOf<AdminUpdateServiceRequest>("description")}
        >
          <Input />
        </Form.Item>

        {/* DeliveryType */}
        <Form.Item
          label="Delivery Type"
          name={NameOf<AdminUpdateServiceRequest>("deliveryType")}
        >
          <Select
            options={[
              { label: "Virtual", value: "Virtual" },
              { label: "InPerson", value: "InPerson" },
              { label: "Both", value: "Both" },
              { label: "Neither", value: "Neither" },
            ]}
          />
        </Form.Item>

        {/* DefaultPrice */}
        <Form.Item
          label="Default Price"
          name={NameOf<AdminUpdateServiceRequest>("defaultPrice")}
        >
          <InputNumber<string | number>
            min={0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>

        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminDataServiceModal;
