import React, { useContext, useState } from "react";
import { Form, Input, Button, Select, DatePicker, Popover } from "antd";
import moment from "moment";
import { NameOf, NotificationUtil } from "src/utils";
import RegisterDTO from "src/models/generated/RegisterDTO";
import { useNavigate } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface RegisterFormData extends RegisterDTO {
  confirmPassword: string;
}

const RegisterPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const [form] = Form.useForm<RegisterFormData>();
  const [loading, setLoading] = useState(false);

  const handleOnFinish = async (values: RegisterFormData) => {
    setLoading(true);
    const results = await authContext.register(values);
    setLoading(false);

    if (results.isAuthenticated) {
      // Show a notification that they have registered successfully
      NotificationUtil.success({
        key: "register-success",
        message: "Registration Successful!",
        description: "You have successfully registered your account. Please check your email to confirm your account.",
        duration: 0
      });
      navigate(RouteConfig.HOME());
      return;
    }

    // Errors and other things show up here
    NotificationUtil.error({
      key: "register-error",
      message: "Registration Failed",
      description: results.message
    });
  };

  return (
    <div className="register-page" style={{ width: "min(500px, calc(100vw - 200px))" }}>
      <Form
        form={form}
        layout="vertical"
        name="register"
        onFinish={handleOnFinish}
      >
        {/* First Name */}
        <Form.Item
          name={NameOf<RegisterFormData>("firstName")}
          label="First Name"
          rules={[{ required: true, message: "Please input your first name!" }]}
        >
          <Input />
        </Form.Item>

        {/* Last Name */}
        <Form.Item
          name={NameOf<RegisterFormData>("lastName")}
          label="Last Name"
          rules={[{ required: true, message: "Please input your last name!" }]}
        >
          <Input />
        </Form.Item>

        {/* Email */}
        <Form.Item
          name={NameOf<RegisterFormData>("email")}
          label="Email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please input a valid email!" },
          ]}
        >
          <Input />
        </Form.Item>

        {/* Password */}
        <Form.Item
          name={NameOf<RegisterFormData>("password")}
          label="Password"
          rules={[
            { required: true, message: "Please input your password!" },
            { min: 8, message: "Password must be at least 8 characters!" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        {/* Confirm Password */}
        <Form.Item
          name={NameOf<RegisterFormData>("confirmPassword")}
          label="Confirm Password"
          rules={[
            { required: true, message: "Please confirm your password!" },
            { min: 8, message: "Password must be at least 8 characters!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(NameOf<RegisterFormData>("password")) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        {/* Date of Birth */}
        {/* <Form.Item
          name={NameOf<RegisterFormData>("dateOfBirth")}
          label={<span>Date of Birth <Popover content="Date of birth is used for verification purposes"><QuestionCircleOutlined /></Popover></span>}
        >
          <DatePicker
          style={{ width: "100%" }}
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          />
        </Form.Item> */}

        {/* Submit */}
        <Form.Item style={{ margin: "unset" }}>
          <Button type='primary' htmlType="submit" size="large" style={{ padding: "0 20px" }} loading={loading}>Register</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterPage;
