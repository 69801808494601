import React from "react";
import { FunctionMapper, KeyMapper, Omit, ReactKey } from "../core/CoreTypes";
import { RouteUrl } from "../core/RouteUrl";

/** Takes all keys from **Routes**, removing prototype, returning the remaining keys with a type of string for each */
type T6 = KeyMapper<Omit<typeof RoutesInternal, "prototype">, string>;

type T7 = FunctionMapper<Omit<typeof RoutesInternal, "prototype">, string>;

type T8 = T7 & { LINK: T6 };

class RoutesInternal {
  /* General */
  public static HOME() { return new RouteUrl("/"); }
  public static SERVICES() { return new RouteUrl("/services"); }
  public static ON_DEMAND() { return new RouteUrl("/on-demand"); }
  public static ABOUT_US() { return new RouteUrl("/about-us"); }
  public static CONTACT_US() { return new RouteUrl("/contact-us"); }
  public static MODERATORS() { return new RouteUrl("/moderators"); }

  /* Authentication */
  public static LOGIN() { return new RouteUrl("/login"); }
  public static LOGIN_ACTIVATE() { return new RouteUrl("/login/activate"); }
  public static LOGIN_TWO_FACTOR() { return new RouteUrl("/login/two-factor"); }
  // The page where any user can request a password reset
  public static LOGIN_FORGOT_PASSWORD() { return new RouteUrl("/login/forgot-password"); }
  public static LOGIN_FORGOT_PASSWORD_RESULT() { return new RouteUrl("/login/forgot-password/result"); }
  // The confirmation page the user gets from the email
  public static LOGIN_RESET_PASSWORD() { return new RouteUrl("/login/reset-password"); }
  public static LOGIN_RESET_PASSWORD_RESULT() { return new RouteUrl("/login/reset-password/result"); }


  public static REGISTER() { return new RouteUrl("/register"); }
  public static LOGOUT() { return new RouteUrl("/logout"); }

  public static EMAIL_VERIFY() { return new RouteUrl("/account/emailconfirm"); }

  /* User Profile */
  public static PROFILE() { return new RouteUrl("/profile"); } // This should show the profile as users see it
  public static PROFILE_EDIT() { return new RouteUrl("/profile/edit"); } // This will actually be the edit page
  public static PROFILE_CHANGE_PASSWORD() { return new RouteUrl("/profile/change-password"); }
  public static PROFILE_CONFIRM_PHONE() { return new RouteUrl("/profile/confirm-phone"); }
  public static PROFILE_CONFIRM_EMAIL() { return new RouteUrl("/profile/confirm-email"); }
  public static PROFILE_APPOINTMENTS() { return new RouteUrl("/profile/appointments"); }

  /* User */
  public static USER_HOME() { return new RouteUrl("/user/home"); }

  /* Admin Stuff */
  public static ADMIN_LIST_USERS() { return new RouteUrl("/admin/users"); }
  public static ADMIN_USERS_ADD() { return new RouteUrl("/admin/users/add"); } // TODO: Gross, please fix
  public static ADMIN_USERS_EDIT(userId: ReactKey) { return new RouteUrl("/admin/users/edit/:userId", { userId }); } // TODO: Gross, please fix
  public static ADMIN_DATA_AREA_SERVICE_LIST() { return new RouteUrl("/admin/data-area/services"); }
  public static ADMIN_DATA_AREA_COUNTRY_CITY_LIST() { return new RouteUrl("/admin/data-area/country-city"); }

  /* Interpreter */
  // I think that having the core interpreters pages in a separate section works. The normal users can get the normal urls
  // So like, interpreters would see /agent/home while users might see /home

  // These will use 'agent' as a prefix because 'interpreters' will be a listing page
  public static INTERPRETER_HOME() { return new RouteUrl("/agent/home"); }
  public static INTERPRETER_CALENDAR() { return new RouteUrl("/agent/calendar"); }
  public static INTERPRETER_AVAILABILITY() { return new RouteUrl("/agent/availability"); }

  /* User Facing */
  // Idk what I was doing with the above area, something about interpreters? Idk
  // This will be what the user sees
  /** Reminder: userPublicId is a GUID */
  public static PUBLIC_SEARCH_INTERPRETER() { return new RouteUrl("/interpreter/search"); }
  public static PUBLIC_VIEW_INTERPRETER(userPublicId: string) { return new RouteUrl("/interpreter/:userPublicId", { userPublicId }); }

  /*
   * OKAY, WHAT AM I DOING WITH MY LIFE?
  Okay, pages, lets get the stupid appointment pages completed. We can stub out the purchase stuff for now, but we need the actual routes
  First off, the user will come into the appointment pages AFTER selecting an interpreter. This will likely be from the search page/profile page
  They will come into the first page and select the date, time and service they want. We MIGHT be able to generate the price, but that will be a later problem
    Especially since the prices are interpreter specific
  Next should be a confirmation page, where they can review the appointment and make sure it's correct. This will be a good place to put the Stripe components so they can pay
    I believe that we can get a confirmation from Stripe that the payment will be successful, taking the user to the final page
  This final page will actually be 2 pages. One page for the "processing" part, where we send of the details to Stripe. More of a loading page
  The actual final page will be a confirmation or an error page. If the payment is successful, we will show a confirmation. If not, we will show an error
    The error will either be from Stripe or from the API. Either way, we do NOT want a notification, we want to show the error on the page itself

  Note: After selecting the interpreter and the details, we will want to create the appointment in the API as pending. This is transitory and wont show anywhere unless the user attempts to pay for it
    ... Which means I, once again, need to update the appointment status model, groan

  Okay, routes. We will need the following:
  - /appointment/create?interpreterId=1234
  - /appointment/confirm?appointmentId=1234
  - /appointment/processing?appointmentId=1234
  - /appointment/complete?appointmentId=1234

  Other appointment related routes
  - /appointment/:appointmentId
  - /appointment/:appointmentId/edit
  - /appointment/:appointmentId/cancel <-- This will be link from the email for easy cancellation
  - /appointment/:appointmentId/confirm <-- Also an email link
  - /appointment/:appointmentId/complete <-- Probably both an email link and from the appointment details page
  - /appointment/:appointmentId/feedback <-- I had not thought about this, but man this is a good idea, thanks Copilot
   */

  /* Appointments */
  public static APPOINTMENT_EDIT(appointmentId: ReactKey) { return new RouteUrl("/appointment/:appointmentId/edit", { appointmentId }); }
  public static APPOINTMENT_CANCEL(appointmentId: ReactKey) { return new RouteUrl("/appointment/:appointmentId/cancel", { appointmentId }); }
  public static APPOINTMENT_CONFIRM(appointmentId: ReactKey) { return new RouteUrl("/appointment/:appointmentId/confirm", { appointmentId }); }
  public static APPOINTMENT_COMPLETE(appointmentId: ReactKey) { return new RouteUrl("/appointment/:appointmentId/complete", { appointmentId }); }
  public static APPOINTMENT_FEEDBACK(appointmentId: ReactKey) { return new RouteUrl("/appointment/:appointmentId/feedback", { appointmentId }); }

  /* New Appointment */
  public static CREATE_APPOINTMENT(interpreterId: ReactKey) { return new RouteUrl("/appointment/create", { interpreterId }); }
  public static CREATE_APPOINTMENT_CONFIRM(appointmentId: ReactKey) { return new RouteUrl("/appointment/confirm", { appointmentId }); }
  public static CREATE_APPOINTMENT_PROCESSING(appointmentId: ReactKey) { return new RouteUrl("/appointment/processing", { appointmentId }); }
  public static CREATE_APPOINTMENT_COMPLETE(appointmentId: ReactKey) { return new RouteUrl("/appointment/complete", { appointmentId }); }

  /* Testing */
  public static TESTING_HOME() { return new RouteUrl("/testing"); }
  public static TESTING_USER_LAYOUT() { return new RouteUrl("/testing/user_layout"); }
  public static TESTING_CENTERED_LAYOUT() { return new RouteUrl("/testing/centered_layout"); }
  public static TESTING_PUBLIC_LAYOUT() { return new RouteUrl("/testing/public_layout"); }


  // UNKNOWN BELOW THIS

  /* Authentication */
  // public static LOGIN_EMAIL() { return new RouteUrl('/login'); }
  // public static LOGIN_ACTIVATE() { return new RouteUrl('/login/activate'); }
  // public static LOGIN_ACTIVATE_SUCCESS() { return new RouteUrl('/login/activate/success'); }
  // public static LOGIN_TWO_FACTOR() { return new RouteUrl('/login/two-factor'); }

  // public static LOGIN_FORGOT_PASSWORD() { return new RouteUrl('/login/forgot-password'); }

  // public static LOGIN_TERMS_OF_SERVICE() { return new RouteUrl('/terms-of-service'); }
  // public static LOGIN_PRIVACY_STATEMENT() { return new RouteUrl('/privacy-statement'); }

  // public static LOGOUT() { return new RouteUrl('/logout'); }

  // /* User Profile */
  // public static PROFILE_HOME() { return new RouteUrl('/profile'); }
  // public static PROFILE_RESET_PASSWORD() { return new RouteUrl('/profile/reset-password'); }
  // public static PROFILE_RESET_PASSWORD_CONFIRM() { return new RouteUrl('/profile/reset-password/confirmation'); }
  // public static PROFILE_VERIFY_PHONE() { return new RouteUrl('/profile/verify-phone'); }

  /* Misc */

  /* Dev */
  public static STYLE_GUIDE() { return new RouteUrl("/style-guide"); }

  /* Error Handling */
  public static ERROR_PAGE() { return new RouteUrl("/the-handler"); }
  public static PROD_ERROR_PAGE() { return new RouteUrl("/errors"); }
  public static PAGE_NOT_FOUND() { return new RouteUrl("/404"); }
}

const generateRoutes = (): T8 => {
  const getOriginalURL = function (key: string): string {
    const RoutesNoAny = RoutesInternal as any;
    // If function, run it and assume RouteUrl as a return type. Else, check for string and return its result
    if (typeof RoutesNoAny[key] === "function") {
      const obj: RouteUrl = RoutesNoAny[key]({});
      if (obj != null) {
        return obj.path;
      }
    } else if (typeof RoutesNoAny[key] === "string") {
      return RoutesNoAny[key];
    }
    // Uh
    return "";
  };

  const getStringVariant = function (key: string) {
    const RoutesNoAny = RoutesInternal as any;
    if (typeof RoutesNoAny[key] === "function") {
      return (...args: any[]) => {
        // Actually call the function, which will return a RouteUrl or a string
        let obj = RoutesNoAny[key](...args) as RouteUrl;
        if (obj != null) {
          return obj.toString();
        } else {
          throw new Error(`Non RouteUrl detected. Please use RouteUrl for this key: ${key}`);
        }
      };
    } else if (typeof RoutesNoAny[key] === "string") {
      return (...args: any[]) => {
        return RoutesNoAny[key](...args) as string;
      };
    }
    // Not a function, so what do we do?
    return null;
  };

  // Keys to be removed. Object functions, prototype and where we are storing the rest
  const spareKeys = ["name", "length", "construct", "prototype", "LINK"];

  const keys = Object.getOwnPropertyNames(RoutesInternal) // Get all properties from our routes list
    .filter(x => !spareKeys.includes(x)); // Remove props that we don't use

  // Get the LINK representation. Useful for url matching, such as the sidenav/menu
  const routeLinks = keys
    .map(x => ({ [x]: getOriginalURL(x) }))
    .reduce((a, b) => ({ ...a, ...b })) as T6;

  const routeToStrings = keys
    .map(x => ({ [x]: getStringVariant(x) }))
    .reduce((a, b) => ({ ...a, ...b })) as T7;

  return { ...routeToStrings, LINK: routeLinks };
};

const RouteConfig = generateRoutes();
export default RouteConfig;
