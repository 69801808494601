// This was inspired by, but not copied from https://www.npmjs.com/package/react-auth-code-input
import React from "react";
import { Image } from "antd";
import InterpreterDisplayResponse from "src/models/generated/InterpreterDisplayResponse";
import { StringUtil } from "src/utils";
import imageNotFound from "src/assets/core/image-not-found.png";
import RouteConfig from "src/config/RouteConfig";
import { Link } from "react-router-dom";

interface InterpreterCardProps {
  value: InterpreterDisplayResponse;
  onChange?: (value: string) => void;
}

const InterpreterCard: React.FC<InterpreterCardProps> = (props) => {
  return <div style={{ width: 220, display: "inline-grid", margin: 8 }}>
    <Image
      style={{ aspectRatio: "4 / 5", objectFit: "cover" }}
      preview={false}
      src={StringUtil.IsNullOrEmpty(props.value.profilePictureUrl) ? "error" : props.value.profilePictureUrl}
      fallback={imageNotFound} />
    <div style={{ backgroundColor: "rgb(242, 241, 242)", height: 140 }}>
      <h3>{props.value.displayName}</h3>
      <p>{props.value.languageProficiencies.map(x => x.languageName)?.join(", ")}</p>
      <Link to={RouteConfig.PUBLIC_VIEW_INTERPRETER(props.value.id)}>View Profile</Link>
    </div>
  </div>;
};

export default InterpreterCard;
