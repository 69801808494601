import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Select, Skeleton, Switch, Tooltip } from "antd";

const CreateAppointmentConfirmPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  return (
    <div>
      <h1>Create Appointment Complete!</h1>
      <span>Data submitted and payment confirmed ~nya</span>
      <br />
      <span>Or something happened and I need to show an error ~nyaa</span>
    </div>
  );
};

export default CreateAppointmentConfirmPage;
