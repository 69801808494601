import { ReactKey } from "./CoreTypes";

/** Handles the internal workings for the routes file */
export class OldRouteUrl {
  /**
   * Returns the original URL provided before adding in the params
   */
  public readonly originalUrl: string;
  /**
   * Returns the original Params as an object
   */
  public readonly originalParams?: Record<string, unknown>;

  /**
   * Returns the route generated from the URL and Params
   */
  public toString(): string {
    // Dev Note: We might be able to use react-router's 'generatePath()' instead
    // import { generatePath } from 'react-router'
    return this.generateRoute();
  }

  /**
   * Creates an instance of RouteUrl
   * @example
   * // Simple
   * RouteUrl('/path/:id', { id });
   *
   * // Complicated. Notice the matching keys and url params
   * RouteUrl('/path/:foo/:bar/:baz?', { foo, bar: id, baz: 'something' });
   *
   * @remarks
   * If params are in the URL and **not** passed into the params param, then the route is not parsed and will equal the url passed in
   *
   * @param {string} url The url as a React Router url string, see examples
   * @param {{}} [params] The params for the url as an object. Items must be keyed corresponding to their name in the url
   */
  constructor(url: string, params?: Record<string, unknown>) {
    // Remove spaces from url
    this.originalUrl = url.replace(/(\r\n|\n|\r| )/gm, "");
    this.originalParams = params; // as { [index: string]: string };
  }

  private generateRoute(): string {
    // If we have no params, skip parsing.
    if (this.originalParams == null || Object.keys(this.originalParams).length < 1) {
      return this.originalUrl;
    }

    const paramKeys = Object.keys(this.originalParams);

    // Purely for readability on the return statement below
    const mapFunction = (split: string): string => {
      let isOptional = false;

      // Check if variable or just string
      if (split[0] !== ":") {
        return split;
      }

      // Remove the ':' from the start of the key
      split = split.slice(1);

      // Check if required
      if (split.slice(-1) === "?") {
        isOptional = true;
        split = split.slice(0, -1);
      }

      // Check if params match. If not, optional params just get let through
      let key = paramKeys.find(y => y === split);
      if (key == null && isOptional) {
        return "";
      } else if (key == null) {
        key = ""; // Dev Note: Probably should throw an error here
      }

      return String(this.originalParams![key]);
    };

    return "/" + this.originalUrl
      .split("/") // Split into chunks
      .map(mapFunction) // Map params to variables
      .filter(x => x != null && x.trim().length > 0) // Remove empty
      .join("/"); // Join everything back together again
  }
}


export class RouteUrl {
  /**
   * Returns the path or URL originally provided
   */
  public readonly path: string;
  /**
   * Returns the required Params as an object
   */
  public readonly requiredParams?: Record<string, React.Key>;

  /**
   * Returns the required Params as an object
   */
  public readonly optionalParams?: Record<string, React.Key>;

  /**
   * Returns the route generated from the URL and Params
   */
  public toString(): string {
    // Dev Note: We might be able to use react-router's 'generatePath()' instead
    // import { generatePath } from 'react-router'
    return this.generateRoute();
  }

  /**
   * Creates an instance of RouteUrl
   * @example
   * // Simple
   * RouteUrl('/path/to/page'); // Outputs: /path/to/page
   *
   * // With required params
   * RouteUrl('/page/:id', { id: 12 }); // Outputs: /page/12
   *
   * // Complicated. Notice the matching keys and the automatic url encoding
   * let id = 12;
   * RouteUrl('/page/:id/edit', { id }, { extra: ["name", "asc" ] }); // Outputs: /page/12/edit?extra=name%2Casc
   *
   * @param url The url as a React Router url string, see examples
   * @param requiredParams The required params for the url as an object. Items must be keyed corresponding to their name in the url. Params will match on those in the URL that in the form ':key'. If a required param is missing, it will be added as a url parameter with a warning
   * @param optionalParams The optional params for the url as an object. Items must be keyed corresponding to their name in the url. Params will match any in the URL with any extras being added as url parameters
   */
  constructor(url: string, requiredParams?: Record<string, ReactKey>, optionalParams?: Record<string, ReactKey>) {
    // Remove spaces from url
    this.path = url.replace(/(\r\n|\n|\r| )/gm, "");
    this.requiredParams = requiredParams;
    this.optionalParams = optionalParams;
  }

  private generateRoute(): string {
    // The old way was a bit convoluted and attempted to track optional params in the url
    // This leads to really strange urls like "/path/:id/:key?/:value/edit"
    // What happens when key is empty? That really feels like it should be a separate page as it resolves to "/path/:id/:value/edit"
    // SO, all variables in the path itself will be required, and all optional params will be added to the end of the url
    // This SIGNIFICANTLY simplifies the code and makes it much easier to anticipate what the url will look like
    // This makes it easier to use optional parameters without having to hack them into the url object

    let requiredKeys = Object.keys(this.requiredParams ?? {});
    let optionalKeys = Object.keys(this.optionalParams ?? {});

    // What to do here, let's loop over the pathKeys (which aren't keys (I hate english)) and build us a path we can be, probably, proud of
    const mapFunction = (split: string): string => {
      // Check if variable or just string
      if (split[0] !== ":") {
        return split;
      }

      // Remove the ':' from the start of the key
      split = split.slice(1);

      // Check if a required param
      if (requiredKeys.includes(split)) {
        // Remove the key from the list of required keys
        requiredKeys = requiredKeys.filter(x => x !== split);
        return String(this.requiredParams![split]);
      }

      // Check if an optional param
      if (optionalKeys.includes(split)) {
        // Remove the key from the list of optional keys
        optionalKeys = optionalKeys.filter(x => x !== split);
        return String(this.optionalParams![split]);
      }

      // If we get here, then that's a problem
      console.warn("Missing required parameter for path: ", split);
      return "";
    };

    const output = this.path
      .split(/(?:\/|\?)+/) // Split into chunks on '/' or '?'
      .map(mapFunction) // Do our fancy map function
      .filter(x => x != null && x.trim().length > 0) // Remove empty, it's your problem if you missed something
      .join("/"); // Smash everything back together

    // Take the left over optionalKeys and add them to the optionalValues
    let optionalValues: [string, any][] = optionalKeys.map(x => [x, this.optionalParams![x]]);

    // Check if we have any required keys left over
    if (requiredKeys.length > 0) {
      // Likely a programming error, warn and add them to the optionalValues
      console.warn("Missing required parameters for path", requiredKeys);

      optionalValues = optionalValues.concat(requiredKeys.map(x => [x, this.requiredParams![x]]));
    }

    // Order doesn't matter, but for testing an consistency, let's sort the optionalValues
    optionalValues = optionalValues.sort((a, b) => a[0].localeCompare(b[0]));

    // Add the optional keys
    const optionalString = optionalValues.length > 0
      ? "?" + optionalValues.map(x => `${x[0]}=${encodeURIComponent(x[1])}`).join("&")
      : "";

    return "/" + output + optionalString;
  }
}

// function doAssert(name: string, expected: string, actual: string) {
//   console.log(`Testing ${name}. Expected: ${expected}`);
//   console.assert(expected === actual, `Expected ${expected} but got ${actual}`);
// }

// // Testing!
// const simpleRoute = new NewRouteUrl("/path/with/nothing");
// doAssert("simpleRoute", "/path/with/nothing", simpleRoute.toString());

// const easyRoute_extraTokens = new NewRouteUrl("/path/with///nothing/?");
// doAssert("easyRoute_extraTokens", "/path/with/nothing", easyRoute_extraTokens.toString());

// const easyRoute = new NewRouteUrl("/path/with/:id", { id: 12 });
// doAssert("easyRoute", "/path/with/12", easyRoute.toString());

// const missingVariable = new NewRouteUrl("/path/with/:id");
// doAssert("missingVariable", "/path/with", missingVariable.toString());

// const extraVariable = new NewRouteUrl("/path/veggies", { potato: "crispy" });
// doAssert("extraVariable", "/path/veggies?potato=crispy", extraVariable.toString());

// const variableInMiddle = new NewRouteUrl("/path/with/:id/and/:something", { id: 12, something: "nothing" });
// doAssert("variableInMiddle", "/path/with/12/and/nothing", variableInMiddle.toString());

// const everything = new NewRouteUrl("/path/with/:id/and/:something/", { id: 12, something: "nothing", mine: "not_yours&mine" }, { else: "this is else" });
// doAssert("everything", "/path/with/12/and/nothing?else=this%20is%20else&mine=not_yours%26mine", everything.toString());
