import React, { useEffect, useState } from "react";
import { Select, Space, InputNumber} from "antd";
import ServiceOfferingRequest from "src/models/generated/ServiceOfferingRequest";
import ServiceDTO from "src/models/generated/ServiceDTO";
import { StyledComponentProps } from "src/core/CoreTypes";

export interface ServiceOfferingSelectorProps {
  services: ServiceDTO[];
  disabled?: boolean;

  value?: ServiceOfferingRequest;
  onChange?: (value: ServiceOfferingRequest) => void;

  // We want to allow the standard classname and style props
  className?: string;
  style?: React.CSSProperties;
}

const sizes = {
  service: 200,
  price: 90,
  billingType: 100,
  minTime: 150,
  maxTime: 150,
};

type MySuperAwesomeComponentType = React.FC<ServiceOfferingSelectorProps> & {
  Label: React.FC<StyledComponentProps>;
};

const ServiceOfferingSelector: MySuperAwesomeComponentType = (props) => {
  const [serviceId, setServiceId] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [billingType, setBillingType] = useState<ServiceOfferingRequest["billingType"]>();
  const [minTime, setMinTime] = useState<number>();
  const [maxTime, setMaxTime] = useState<number>();

  const handleTimeFormatting = (value?: string | number): string => {
    const hours = String(Math.floor(Number(value) / 60));
    const minutes = String(Number(value) % 60);
    return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
  };

  const handleTimeParsing = (value?: string): number => {
    const [hours, minutes] = value!.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    // Round to nearest 5
    return Math.round(totalMinutes / 5) * 5;
  };

  // Need handlers for all of the fields (service, price, minTime, maxTime)
  const handleServiceChange = (serviceId: number) => {
    setServiceId(serviceId);
    props.onChange?.(ServiceOfferingRequest.create({ serviceId, price, minTime, maxTime, billingType }));
  };

  const handlePriceChange = (price: number) => {
    setPrice(price);
    props.onChange?.(ServiceOfferingRequest.create({ serviceId, price, minTime, maxTime, billingType }));
  };

  const handleMinTimeChange = (minTime: number) => {
    setMinTime(minTime);
    props.onChange?.(ServiceOfferingRequest.create({ serviceId, price, minTime, maxTime, billingType }));
  };

  const handleMaxTimeChange = (maxTime: number) => {
    setMaxTime(maxTime);
    props.onChange?.(ServiceOfferingRequest.create({ serviceId, price, minTime, maxTime, billingType }));
  };

  const handleBillingTypeChange = (billingType: ServiceOfferingRequest["billingType"]) => {
    setBillingType(billingType);
    props.onChange?.(ServiceOfferingRequest.create({ serviceId, price, minTime, maxTime, billingType }));
  };

  useEffect(() => {
    if (props.value == null) {
      return;
    }

    setServiceId(props.value.serviceId);
    setPrice(props.value.price);
    setBillingType(props.value.billingType!);
    setMinTime(props.value.minTime!);
    setMaxTime(props.value.maxTime!);
  }, [props.value]);

  // We will want to group the services by deliveryType. Object.GroupBy goes by GroupByToMap or some bs, and it's not worth trying to patch together something that will likely break
  // So we do it manually for the keys we are aware of
  let serviceGroups = [
    {
      label: "InPerson",
      options: props.services.filter(x => x.deliveryType === "InPerson").map(x => ({ label: x.name, value: x.id }))
    },
    {
      label: "Virtual",
      options: props.services.filter(x => x.deliveryType === "Virtual").map(x => ({ label: x.name, value: x.id }))
    },
    {
      label: "Both",
      options: props.services.filter(x => x.deliveryType === "Both").map(x => ({ label: x.name, value: x.id }))
    },
    {
      label: "Neither",
      options: props.services.filter(x => x.deliveryType === "Neither").map(x => ({ label: x.name, value: x.id }))
    },
  ];

  // Remove empty groups
  serviceGroups = serviceGroups.filter(x => x.options.length > 0);

  return (
    <Space size={8}>
      {/* Select Service */}
      <Select
        style={{ width: sizes.service }}
        placeholder="Select Service"
        disabled={props.disabled}
        options={serviceGroups}
        value={serviceId}
        onChange={handleServiceChange}
      />

      {/* Price */}
      <InputNumber<string | number>
        style={{ width: sizes.price }}
        placeholder="Price"
        min={0}
        step={5}
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={value => value!.replace(/\$\s?|(,*)/g, "")}
        disabled={props.disabled}
        value={price}
        onChange={e => handlePriceChange(Number(e))}
      />

      {/* Billing Type */}
      <Select
        style={{ width: sizes.billingType }}
        placeholder="Billing Type"
        disabled={props.disabled}
        options={[
          { label: "Fixed", value: "Fixed" },
          { label: "Hourly", value: "Hourly" },
        ]}
        value={billingType}
        onChange={handleBillingTypeChange}
      />

      {/* Min Time */}
      <InputNumber<string | number>
        style={{ width: sizes.minTime }}
        placeholder="Min Time"
        min={0}
        step={!minTime || minTime < 60 ? 5 : 15}
        formatter={handleTimeFormatting}
        parser={handleTimeParsing}
        disabled={props.disabled}
        value={minTime}
        onChange={e => handleMinTimeChange(Number(e))}
      />

      {/* Max Time */}
      <InputNumber<string | number>
        style={{ width: sizes.maxTime }}
        placeholder="Max Time"
        min={0}
        step={!maxTime || maxTime < 60 ? 5 : 15}
        formatter={handleTimeFormatting}
        parser={handleTimeParsing}
        disabled={props.disabled}
        value={maxTime}
        onChange={e => handleMaxTimeChange(Number(e))}
      />
    </Space>
  );
};

// Adds a custom Label to the component
ServiceOfferingSelector.Label = (props: StyledComponentProps) => {
  return (<Space size={8} {...props}>
    <div style={{ width: sizes.service }}>Service</div>
    <div style={{ width: sizes.price }}>Price</div>
    <div style={{ width: sizes.billingType }}>Billing Type</div>
    <div style={{ width: sizes.minTime }}>Min Time (HH:MM)</div>
    <div style={{ width: sizes.maxTime }}>Max Time (HH:MM)</div>
  </Space>);
};

export default ServiceOfferingSelector;
