import React, { useEffect, useState } from "react";
import { Button, Collapse, Form, Input, Skeleton } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { NameOf } from "src/utils";
import NotificationUtil from "src/utils/NotificationUtil";
import LanguageProficiencySelector from "src/components/LanguageProficiencySelector";
import AddressDTO from "src/models/generated/AddressDTO";
import ServiceOfferingRequest from "src/models/generated/ServiceOfferingRequest";
import ServiceOfferingSelector from "src/components/ServiceOfferingSelector";
import ServiceDTO from "src/models/generated/ServiceDTO";
import DataController from "src/api/DataController";
import UserProfileDTO from "src/models/generated/UserProfileDTO";
import ServiceOfferingSelectorExp from "src/components/ServiceOfferingSelectorExp";

interface ExpertiseFormData extends UserProfileDTO {
}

interface ExpertiseProps {
}

const ExpertiseTab: React.FC<ExpertiseProps> = (props) => {
  // Dev Note: Use this to get the form since we are child of the tabs
  const form = Form.useFormInstance<ExpertiseFormData>();
  const [services, setServices] = useState<ServiceDTO[]>([]);
  const [servicesLoading, setServicesLoading] = useState(false);

  // Form list items related
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  const loadServices = async () => {
    setServicesLoading(true);
    try {
      const result = await DataController.getServices();
      setServices(result.data);
    } catch (error) {
      NotificationUtil.error({
        key: "ExpertiseTab",
        message: "Services",
        description: "Failed to load services",
        error
      });
    }
    setServicesLoading(false);
  };

  useEffect(() => {
    loadServices(); // TODO: This should be in at an app level
  }, []);

  return (
    <>
      <Collapse bordered={false} defaultActiveKey={["1", "2", "3"]}>
        {/* Language Proficiencies */}
        <Collapse.Panel header="Language Proficiencies" key="1">
          <Form.List
            name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("languageProficiencies")]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    // label={index === 0 ? "Language Proficiencies" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          validator(_, value) {
                            if (value == null) {
                              return Promise.reject(new Error("Proficiency empty. Please fill or remove"));
                            }
                            if (value.languageCode == null) {
                              return Promise.reject(new Error("Please select a Language"));
                            }
                            if (value.proficiencyId == null) {
                              return Promise.reject(new Error("Please select a Proficiency Level"));
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      noStyle
                    >
                      <LanguageProficiencySelector />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{
                        position: "relative",
                        top: 4,
                        marginLeft: 8,
                        fontSize: 24,
                        cursor: "pointer",
                        transition: "all 0.3"
                      }}
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Proficiency
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Collapse.Panel>

        {/* Interpreting Services */}
        <Collapse.Panel header="Interpreting Services" key="2">
          <Form.List name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("serviceOfferings")]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.length > 0 && <ServiceOfferingSelector.Label />}
                {fields.map((field, index) => {
                  return (
                    <>
                      <Form.Item
                        // This is just formItemLayout as we don't need a label for for the list
                        {...formItemLayout}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          rules={[
                            {
                              required: true,
                              validator(_, value: ServiceOfferingRequest) {
                                if (value == null) {
                                  return Promise.reject(new Error("Service empty. Please fill or remove"));
                                }
                                if (value.serviceId == null) {
                                  return Promise.reject(new Error("Please select a Service"));
                                }
                                if (value.price == null || value.price <= 0) {
                                  return Promise.reject(new Error("Please add a price for the Service"));
                                }
                                if (value.billingType == null) {
                                  return Promise.reject(new Error("Please select a Billing Type"));
                                }
                                if (value.minTime == null || value.minTime < 0) {
                                  return Promise.reject(new Error("Please add a minimum time for the Service"));
                                }
                                if (value.maxTime == null || value.maxTime < 0) {
                                  return Promise.reject(new Error("Please add a maximum time for the Service"));
                                }

                                // Max time needs to be greater than min time
                                if (value.maxTime < value.minTime) {
                                  return Promise.reject(new Error("Maximum time must be greater the or equal to the minimum time"));
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                          noStyle
                        >
                          <ServiceOfferingSelector services={services} />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{
                            position: "relative",
                            top: 4,
                            marginLeft: 8,
                            fontSize: 24,
                            cursor: "pointer",
                            transition: "all 0.3",
                          }}
                          onClick={() => remove(field.name)}
                        />
                      </Form.Item>
                    </>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Service
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Collapse.Panel>

        {/* Experimental Interpreting Services */}
        <Collapse.Panel header="Interpreting Services (Experimental)" key="22">
          {/* Describe how this is just for experimenting with a concept and will not be saved */}
          <p>These are experimental designs and will not be saved. They are for testing and concept development only</p>
          <Form.List name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("serviceOfferings") + "_exp"]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <>
                      {fields.length > 0 && <ServiceOfferingSelectorExp.Label />}
                      <Form.Item
                        {...formItemLayout}
                        required={false}
                        key={field.key}
                      >
                        <div
                          style={{ display: "grid", gridTemplateColumns: "1fr 64px", alignItems: "start" }}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                validator(_, value: ServiceOfferingRequest) {
                                  if (value == null) {
                                    return Promise.reject(new Error("Service empty. Please fill or remove"));
                                  }

                                  // Validations have been removed for experimental purposes as it adds unnecessary complexity to a concept design ~nya

                                  return Promise.resolve();
                                },
                              },
                            ]}
                            noStyle
                          >
                            <ServiceOfferingSelectorExp services={services} />
                          </Form.Item>
                          <MinusCircleOutlined
                            style={{
                              position: "relative",
                              top: 4,
                              marginLeft: 8,
                              fontSize: 24,
                              cursor: "pointer",
                              transition: "all 0.3",
                            }}
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      </Form.Item>
                    </>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Service
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Collapse.Panel>

        {/* Mailing Address */}
        <Collapse.Panel header="Mailing Address" key="3">
          <Form.Item label="Mailing Address">
            <p>This is the users mailing address and will be used for things like stripe, communication and verification</p>

            {/* Address Line 1 */}
            <Form.Item
              label='Address Line 1'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("mailingAddress"), NameOf<AddressDTO>("addressLine1")]}
            >
              <Input allowClear />
            </Form.Item>

            {/* Address Line 2 */}
            <Form.Item
              label='Address Line 2'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("mailingAddress"), NameOf<AddressDTO>("addressLine2")]}
            >
              <Input allowClear />
            </Form.Item>

            {/* Address Line 3 */}
            <Form.Item
              label='Address Line 3'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("mailingAddress"), NameOf<AddressDTO>("addressLine3")]}
            >
              <Input allowClear />
            </Form.Item>

            {/* City */}
            <Form.Item
              label='City'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("mailingAddress"), NameOf<AddressDTO>("city")]}
            >
              <Input allowClear />
            </Form.Item>

            {/* Region */}
            <Form.Item
              label='State or Region'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("mailingAddress"), NameOf<AddressDTO>("region")]}
            >
              <Input allowClear />
            </Form.Item>

            {/* Postal Code */}
            <Form.Item
              label='Zip or Postal Code'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("mailingAddress"), NameOf<AddressDTO>("postalCode")]}
            >
              <Input allowClear />
            </Form.Item>

            {/* Country */}
            <Form.Item
              label='Country'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("mailingAddress"), NameOf<AddressDTO>("country")]}
            >
              <Input allowClear />
            </Form.Item>
          </Form.Item>
        </Collapse.Panel>

        {/* Current Address */}
        <Collapse.Panel header="Current Address" key="4">
          <Form.Item label="Current Address">
            <p>The current address is the city and country you are currently in</p>

            {/* Address Line 1 */}
            {/* <Form.Item
              label='Address Line 1'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("currentAddress"), NameOf<AddressDTO>("addressLine1")]}
            >
              <Input allowClear />
            </Form.Item> */}

            {/* Address Line 2 */}
            {/* <Form.Item
              label='Address Line 2'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("currentAddress"), NameOf<AddressDTO>("addressLine2")]}
            >
              <Input allowClear />
            </Form.Item> */}

            {/* Address Line 3 */}
            {/* <Form.Item
              label='Address Line 3'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("currentAddress"), NameOf<AddressDTO>("addressLine3")]}
            >
              <Input allowClear />
            </Form.Item> */}


            <Form.Item>
              <div style={{width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr", gap: 8}}>
                {/* City */}
                <Form.Item
                  label='City'
                  name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("currentAddress"), NameOf<AddressDTO>("city")]}
                >
                  <Input allowClear />
                </Form.Item>

                {/* Region */}
                <Form.Item
                  label='State or Region'
                  name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("currentAddress"), NameOf<AddressDTO>("region")]}
                >
                  <Input allowClear />
                </Form.Item>
              </div>
            </Form.Item>

            {/* Postal Code */}
            {/* <Form.Item
              label='Zip or Postal Code'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("currentAddress"), NameOf<AddressDTO>("postalCode")]}
            >
              <Input allowClear />
            </Form.Item> */}

            {/* Country */}
            <Form.Item
              label='Country'
              name={[NameOf<ExpertiseFormData>("expertise"), NameOf<ExpertiseFormData["expertise"]>("currentAddress"), NameOf<AddressDTO>("country")]}
            >
              <Input allowClear />
            </Form.Item>
          </Form.Item>
        </Collapse.Panel>

      </Collapse>
    </>
  );
};

export default ExpertiseTab;
