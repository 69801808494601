// This will be a standard contact us page with just a phone number and email address for now
// We can add a form later if we want

import React, { useEffect, useState } from "react";

const ContactUsPage: React.FC = () => {
  const supportPhoneUSA = "512-207-0505";
  const supportPhoneGreece = "+30 694 4279613";
  const supportEmail = "ukrdirector@gmail.com";

  return (
    <div>
        <h1>Contact Us</h1>
        <h3>Phone Number</h3>
        <p>
          <span>For general questions, please call us at </span>
          <a href={`tel:${supportPhoneUSA}`}>
            {supportPhoneUSA}
          </a>
          <span> or </span>
          <a href={`tel:${supportPhoneGreece}`}>
            {supportPhoneGreece}
          </a>
        </p>

        <h3>Email</h3>
        <p>
          <span>For general questions, please email us at </span>
          <a href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
    </div>
  );
};

export default ContactUsPage;
