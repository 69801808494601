import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Select, Skeleton, Switch, Tooltip } from "antd";

const CreateAppointmentConfirmPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      // const result = await AccountController.updateCurrentUserProfile(values);

      // Update the current profile data
      // await authContext.updateProfile(values);
      // setFormData(result.data);
      // form.resetFields();

      // NotificationUtil.success({
      //   key: "ProfilePage",
      //   message: "Profile",
      //   description: "Profile has been updated"
      // });
    } catch (error) {
      // NotificationUtil.error({
      //   key: "ProfilePage",
      //   message: "Profile",
      //   description: "Error while saving profile",
      // });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h1>Confirm Appointment</h1>
      <span>something here about the appointment itself, maybe a <code>Descriptions</code> component ~nya?</span>
    </div>
  );
};

export default CreateAppointmentConfirmPage;
