import { Button, Carousel, Col, Form, Layout, Menu, MenuProps, Row, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import joinTeamImage from "src/assets/people-giving-thumbs-up.jpg";
import { NameOf, StringUtil } from "src/utils";
import UserController from "src/api/UserController";
import InterpreterDisplayResponse from "src/models/generated/InterpreterDisplayResponse";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import InterpreterCard from "src/pages/interpreter/InterpreterCard";
import imageNotFound from "src/assets/core/image-not-found.png";
import lowerLogo from "src/assets/site-logo.png";
import "./HomeLayout.less";
import DataController from "src/api/DataController";
import CountryDTO from "src/models/generated/CountryDTO";
import CityDTO from "src/models/generated/CityDTO";
import ServiceDTO from "src/models/generated/ServiceDTO";
import LanguageDTO from "src/models/generated/LanguageDTO";
import CountryCityListDTO from "src/models/generated/CountryCityListDTO";
import StandardHeader from "./headers/StandardHeader";
import StandardFooter from "./footers/StandardFooter";
import HomeHeader from "./headers/HomeHeader";

const { Content, Header, Footer } = Layout;

interface QuickSearchFormData {
  country?: string;
  city?: string;
  service?: string;
  language?: string;
}

const HomeLayout: React.FC<React.PropsWithChildren> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<QuickSearchFormData>();
  const navigate = useNavigate();
  const [interpreters, setInterpreters] = useState<InterpreterDisplayResponse[]>([]);
  const [countries, setCountries] = useState<CountryCityListDTO[]>([]);
  const [cities, setCities] = useState<CityDTO[]>([]);
  const [services, setServices] = useState<ServiceDTO[]>([]);
  const [languages, setLanguages] = useState<LanguageDTO[]>([]);

  // Watch for changes in the form for country
  const selectedCountryId = Form.useWatch(NameOf<QuickSearchFormData>("country"), form);

  const items: MenuProps["items"] = [
    { key: "1", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Home</NavLink> },
    { key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.SERVICES }}>Services</NavLink> },
    { key: "3", label: <NavLink to={{ pathname: RouteConfig.LINK.ON_DEMAND }}>On-Demand</NavLink> },
    { key: "4", label: <NavLink to={{ pathname: RouteConfig.LINK.ABOUT_US }}>About Us</NavLink> },
    { key: "5", label: <NavLink to={{ pathname: RouteConfig.LINK.CONTACT_US }}>Contact Us</NavLink> }
  ];

  // Add either login or profile depending on if the user is logged in or not
  if (authContext.isLoggedIn) {
    items.push({ key: "6", label: <NavLink to={{ pathname: RouteConfig.LINK.PROFILE_EDIT }}>Profile</NavLink> });
  } else {
    items.push({ type: "divider" });
    items.push({ key: "7", label: <NavLink to={{ pathname: RouteConfig.LINK.LOGIN }}>Log In</NavLink> });
    items.push({ key: "8", label: <NavLink to={{ pathname: RouteConfig.LINK.REGISTER }}>Sign Up</NavLink> });
  }

  const countryOptions = countries.map(x => ({ label: x.countryName, value: x.countryId.toString() }));
  // For cities, we need a country first, then we can load the list from the country
  const cityOptions = selectedCountryId
    ? countries
        .filter((x) => x.countryId.toString() === selectedCountryId)
        .flatMap((x) => x.cities)
        .map((x) => ({ label: x.name, value: x.id.toString() }))
    : [];
  const serviceOptions = services.map(x => ({ label: x.name, value: x.id.toString() }));
  const languageOptions = languages.map(x => ({ label: x.name, value: x.code }));

  // const countries: { label: string, value: string }[] = [
  //   { value: "0", label: "Any" },
  //   { value: "1", label: "Ukraine" }
  // ];
  // const cities: { label: string, value: string }[] = [
  //   { value: "0", label: "Any" },
  //   { value: "1", label: "Kyiv" },
  //   { value: "2", label: "Kharkiv" },
  //   { value: "3", label: "Odesa" },
  //   { value: "4", label: "Dnipro" },
  //   { value: "5", label: "Donetsk" },
  //   { value: "6", label: "Zaporizhzhia" },
  //   { value: "7", label: "Lviv" },
  //   { value: "8", label: "Kryvyi Rih" },
  //   { value: "9", label: "Mykolaiv" },
  //   { value: "10", label: "Mariupol" },
  //   { value: "11", label: "Luhansk" },
  //   { value: "12", label: "Sevastopol" },
  //   { value: "13", label: "Vinnytsia" },
  //   { value: "14", label: "Makiivka" },
  //   { value: "15", label: "Simferopol" },
  //   { value: "16", label: "Kherson" },
  //   { value: "17", label: "Poltava" },
  //   { value: "18", label: "Chernihiv" },
  //   { value: "19", label: "Cherkasy" },
  //   { value: "20", label: "Sumy" },
  // ];
  // const languages: { label: string, value: string }[] = [
  //   { value: "0", label: "Any" },
  //   { value: "en", label: "English" },
  //   { value: "uk", label: "Ukrainian" },
  //   { value: "ru", label: "Russian" },
  //   { value: "fr", label: "French" },
  //   { value: "de", label: "German" },
  //   { value: "es", label: "Spanish" },
  //   { value: "it", label: "Italian" },
  //   { value: "ja", label: "Japanese" },
  //   { value: "ko", label: "Korean" },
  // ];
  // const services: { label: string, value: string }[] = [
  //   { value: "1", label: "Coming soon!" }
  // ];

  const loadInterpretersGrid = async () => {
    try {
      const results = await UserController.getHomepageList();
      setInterpreters(results.data);
    } catch (error) {
      // Whoops! Seems there is no api today ~nya
      console.error(error);
    }
  };

  const loadCountries = async () => {
    try {
      // STOPPED HERE: Will need to convert to CountryCityDTO and manually load the cities when none are detected. Might need an "is loaded" flag on the dto...
      const results = await DataController.getCountries();
      // Convert from CountryDTO to CountryCityListDTO
      const countries = results.data.map(x => CountryCityListDTO.create({ countryId: x.id, countryName: x.name }));
      setCountries(countries);
    } catch (error) {
      // Whoops! Seems there is no api today ~nya
      console.error(error);
    }
  };

  const loadServices = async () => {
    try {
      const results = await DataController.getServices();
      setServices(results.data);
    } catch (error) {
      // Whoops! Seems there is no api today ~nya
      console.error(error);
    }
  };

  const loadLanguages = async () => {
    try {
      const results = await DataController.getLanguages();
      setLanguages(results.data);
    } catch (error) {
      // Whoops! Seems there is no api today ~nya
      console.error(error);
    }
  };

  const loadCitiesByCountry = async (countryId: string) => {
    // Check if the country has cities, if not, load them
    const countryIndex = countries.findIndex((x) => x.countryId.toString() === countryId);
    const country = countries[countryIndex];
    if (country == null)
      // Should basically not happen
      return;

    if (country.cities.length > 0) {
      setCities(country.cities);
      return;
    }

    try {
      const results = await DataController.getCitiesByCountry(countryId);
      const cities = results.data.cities;

      // Replace the country with the new one
      const newCountry = CountryCityListDTO.create(results.data);
      const newCountries = [...countries];
      newCountries.splice(countryIndex, 1, newCountry);

      setCities(cities);
      setCountries(newCountries);
    } catch (error) {
      // Whoops! Seems there is no api today ~nya
      console.error(error);
    }
  };

  const handleOnFinish = async (values: QuickSearchFormData) => {
    // Convert values from value to label
    values.city = values.city === "0" ? undefined : cityOptions.find(x => x.value === values.city)?.label ?? "";
    values.country = values.country === "0" ? undefined : countryOptions.find(x => x.value === values.country)?.label ?? "";
    values.language = values.language === "0" ? undefined : languageOptions.find(x => x.value === values.language)?.label ?? "";
    values.service = values.service === "0" ? undefined : serviceOptions.find(x => x.value === values.service)?.label ?? "";

    values = Object.fromEntries(Object.entries(values).filter(([key, value]) => {
      console.log("AHH", key, value);
      return value;
    }));

    let route = RouteConfig.PUBLIC_SEARCH_INTERPRETER();
    let searchParams = new URLSearchParams(values as any); // Why TS, why
    navigate(`${route}?${searchParams}`);
  };

  useEffect(() => {
    loadInterpretersGrid();
    loadCountries();
    loadServices();
    loadLanguages();
  }, []);

  return (
    <Layout className='home-layout'>
      <Row className='expandable-container'>

        {/* Mobile */}
        <Col xs={24} sm={24} md={24} lg={0}>
          <StandardHeader menuItems={items} header="home" />
          <Content className='content-container mobile'>
            <Content>
              <div className='header-container'>
              </div>
              <div className='blue-blur-container'>
                <h2 className="with-text-align-center">Interpreting Services</h2>
                <p className='with-special-paragraph'>Interpreting services available 24/7 for any industry, in any language! Consecutive interpretation, simultaneous interpretation, sight interpretations, and more.</p>
              </div>

              <br />
              <br />
              {/* Quick search */}
              <div className='quick-search-container' style={{ padding: 20 }}>
                <Form
                  size='large'
                  layout='vertical'
                  requiredMark={false}
                  // style={{width: "100%"}}
                  form={form}
                  // initialValues={defaultSearch}
                  onFinish={handleOnFinish}
                >
                  <h2 className="with-text-align-center">Search for Interpreters!</h2>
                  <Form.Item
                    label='Country'
                    name={NameOf<QuickSearchFormData>("country")}
                    // rules={[
                    //   { required: true, message: "Country is required" }
                    // ]}
                    >
                    <Select options={countries} placeholder='Country' />
                  </Form.Item>
                  <Form.Item
                    label='City'
                    name={NameOf<QuickSearchFormData>("city")}
                    // rules={[
                    //   { required: true, message: "City is required" }
                    // ]}
                    >
                    <Select options={cities} placeholder='City' />
                  </Form.Item>
                  <Form.Item
                    label='Service'
                    name={NameOf<QuickSearchFormData>("service")}
                    // rules={[
                    //   { required: true, message: "Service is required" }
                    // ]}
                    >
                    <Select options={services} placeholder='Service' />
                  </Form.Item>
                  <Form.Item
                    label='Language'
                    name={NameOf<QuickSearchFormData>("language")}
                    // rules={[
                    //   { required: true, message: "Language is required" }
                    // ]}
                    >
                    <Select options={languages} placeholder='Language' />
                  </Form.Item>

                  <Form.Item label='&nbsp;'>
                    <Button type='primary' htmlType='submit' block>Find</Button>
                  </Form.Item>
                </Form>
              </div>

              {/* Interpreters Grid */}
              <div className='our-interpreters'>
                <h1 className="with-margin-large with-text-align-center">Our Interpreters and Guides</h1>
                <Carousel style={{
                  textAlign: "center",
                  background: "#364d79",
                  paddingBottom: 24,
                  paddingTop: 8
                }}
                  autoplay
                  arrows
                  autoplaySpeed={5000}
                  pauseOnHover
                  pauseOnFocus
                  speed={500}
                  adaptiveHeight
                >
                  {interpreters.map((x, i) => <InterpreterCard key={i} value={x} />)}
                </Carousel>
              </div>

              {/* Join us section */}
              <h1 className="with-margin-large with-text-align-center">Join Our Team</h1>
              <div>
                <img className='join-team-image' src={joinTeamImage} />
                <div className="blue-blur-container">
                  <p className='with-special-paragraph'>Fill your information and join our team. This site is dedicated to helping travelers and businesses find professional, affordable, and reliable interpreters, as well as personal guides in the country of Ukraine. Professionalism and integrity are the cornerstones of our business.</p>
                </div>
              </div>
            </Content>
          </Content>
        </Col>

        {/* Desktop */}
        <Col xs={0} sm={0} md={0} lg={24}>
          <Content className='content-container'>
          <StandardHeader menuItems={items} header="home" />
            <Content>
              {/* Header */}
              <div className='with-grid-layout-half header-container'>
                <div className='left-side'></div>
                <Row className='blue-blur-container with-padding-very-large' align='middle'>
                  <div>
                    <h1>Interpreting Services</h1>
                    <p className='with-special-paragraph'>Interpreting services available 24/7 for any industry, in any language! Consecutive interpretation, simultaneous interpretation, sight interpretations, and more.</p>
                  </div>
                </Row>
              </div>

              {/* Quick search */}
              <div className='quick-search-container'>
                <Form
                  size='large'
                  layout='vertical'
                  requiredMark={false}
                  form={form}
                  onFinish={handleOnFinish}
                >
                  <Form.Item
                    label='Country'
                    name={NameOf<QuickSearchFormData>("country")}
                    // rules={[
                    //   { required: true, message: "Country is required" }
                    // ]}
                    >
                    <Select allowClear options={countryOptions} placeholder='Country' onChange={value => loadCitiesByCountry(value)} onClear={() => console.log("Cleaned Country")} />
                  </Form.Item>
                  <Form.Item
                    label='City'
                    name={NameOf<QuickSearchFormData>("city")}
                    // rules={[
                    //   { required: true, message: "City is required" }
                    // ]}
                    >
                    <Select allowClear options={cityOptions} placeholder='City' />
                  </Form.Item>
                  <Form.Item
                    label='Service'
                    name={NameOf<QuickSearchFormData>("service")}
                    // rules={[
                    //   { required: true, message: "Service is required" }
                    // ]}
                    >
                    <Select allowClear options={serviceOptions} placeholder='Service' />
                  </Form.Item>
                  <Form.Item
                    label='Language'
                    name={NameOf<QuickSearchFormData>("language")}
                    // rules={[
                    //   { required: true, message: "Language is required" }
                    // ]}
                    >
                    <Select allowClear options={languageOptions} placeholder='Language' />
                  </Form.Item>

                  <Form.Item label='&nbsp;'>
                    <Button type='primary' htmlType='submit' block>Find</Button>
                  </Form.Item>
                </Form>
              </div>

              {/* Interpreters Grid */}
              <div className='our-interpreters'>
                <h1>Our Interpreters and Guides</h1>
                <div>
                  {interpreters.map((x, i) => <InterpreterCard key={i} value={x} />)}
                </div>
              </div>

              {/* Join us section */}
              <h1 className='with-text-align-center with-margin-top-large without-margin-bottom'>Join Our Team</h1>
              <div className='with-grid-layout-half'>
                <img className='join-team-image with-padding-large' src={joinTeamImage} />
                <Row align='middle'>
                  <p className='with-special-paragraph with-padding-very-large'>Fill your information and join our team. This site is dedicated to helping travelers and businesses find professional, affordable, and reliable interpreters, as well as personal guides in the country of Ukraine. Professionalism and integrity are the cornerstones of our business.</p>
                </Row>
              </div>
            </Content>
          </Content>
        </Col>
      </Row>
      <StandardFooter />
    </Layout>
  );
};

export default HomeLayout;
