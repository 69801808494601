import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Switch, Tooltip, Popover } from "antd";
import AdminCountryRequest from "src/models/generated/AdminCountryRequest";
import { NameOf, ObjectUtil } from "src/utils";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface AdminDataCountryModalProps {
  open: boolean;
  value?: AdminCountryRequest;
  loading?: boolean;
  onCancel: () => void;
  onFinished: (value: AdminCountryRequest) => void;
}

const AdminDataCountryModal: React.FC<AdminDataCountryModalProps> = (props) => {
  const [form] = Form.useForm();

  const handleFinished = (value: AdminCountryRequest) => {
    if (props.value != null) value = { ...value, id: props.value.id };
    props.onFinished?.(ObjectUtil.TrimValues(value));
  };

  // useEffect(() => {
  //   if (!props.open || props.value == null) {
  //     form.resetFields();
  //     return;
  //   }
  //   form.setFieldsValue(props.value);
  // }, [props.value, props.open]);

  useEffect(() => {
    if (props.value == null) {
      form.resetFields();
      return;
    }
    form.setFieldsValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!props.open) {
      form.resetFields();
    }
  }, [props.open]);

  const approvedTooltip = (
    <span>
      Allows the country to be selected and shown to users. Disabling this effectively &ldquo;deletes&rdquo; the country
      without removing data.
    </span>
  );

  return (
    <Modal
      open={props.open}
      title={props.value?.id != null ? "Edit Country" : "Create Country"}
      confirmLoading={props.loading}
      onCancel={props.onCancel}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleFinished}
        labelCol={{ flex: "0 0 110px" }}
      >
        {/* Name */}
        <Form.Item
          label="Name"
          name={NameOf<AdminCountryRequest>("name")}
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>

        {/* IsAdminApproved */}
        <Form.Item
          label="Approved"
          name={NameOf<AdminCountryRequest>("isAdminApproved")}
          valuePropName="checked"
        >
          <Form.Item noStyle>
            <Switch />
          </Form.Item>

          {/* Tooltip - This is done as adding it to the label with 'click' event does not work on mobile as there is no hover for a tap */}
          <Form.Item noStyle>
            <Popover
              content={approvedTooltip}
              trigger={["hover", "focus"]}
              overlayStyle={{ maxWidth: 444 }}
              mouseLeaveDelay={0.2}
            >
              <QuestionCircleOutlined style={{ marginLeft: 8 }} />
            </Popover>
          </Form.Item>
        </Form.Item>

        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminDataCountryModal;
