import RouteConfig from "./RouteConfig";
import { PageInterface } from "../core/PageInterface";

// Layouts
import UserLayout from "src/layouts/UserLayout";
import PublicLayout from "src/layouts/PublicLayout";
import HomeLayout from "src/layouts/HomeLayout";
import CenteredLayout from "src/layouts/CenteredLayout";
import DevLayout from "src/layouts/DevLayout";

/* Pages */
import HomePage from "src/pages/HomePage";
import StyleGuide from "src/pages/0Dev/StyleGuide";
import DevelopmentTestingPage from "src/pages/0Dev/DevelopmentTestingPage";
import LoginPage from "src/pages/login/LoginPage";
import RegisterPage from "src/pages/register/Register";

// Admin Pages
import AdminUsersListPage from "src/pages/admin/AdminUsersListPage";
import AdminUsersAddPage from "src/pages/admin/AdminUsersAddPage";
import AdminUsersEditPage from "src/pages/admin/AdminUsersEditPage";
import AdminDataServiceList from "src/pages/admin/dataArea/AdminDataServiceList";

// Login
// import LoginEmailPage from 'src/pages/login/LoginEmailPage';
// import LogoutPage from 'src/pages/login/Logout';
import LoginForgotPasswordPage from "src/pages/login/LoginForgotPasswordPage";
import LoginForgotPasswordConfirmPage from "src/pages/login/LoginForgotPasswordConfirmPage";
import LoginResetPasswordPage from "src/pages/login/LoginResetPasswordPage";

// User Facing
import InterpreterProfilePage from "src/pages/interpreter/InterpreterProfilePage";

// Profile
import ProfilePage from "src/pages/profile/ProfilePage";
import EditProfilePage from "src/pages/profile/edit/EditProfilePage";
import InterpreterSearchPage from "src/pages/interpreter/InterpreterSearchPage";
import CreateAppointmentPage from "src/pages/createAppointment/CreateAppointmentPage";
import CreateAppointmentConfirmPage from "src/pages/createAppointment/CreateAppointmentConfirmPage";
import CreateAppointmentProcessingPage from "src/pages/createAppointment/CreateAppointmentProcessingPage";
import CreateAppointmentCompletePage from "src/pages/createAppointment/CreateAppointmentCompletePage";
import ServicesPage from "src/pages/home/ServicesPage";
import OnDemandPage from "src/pages/home/OnDemandPage";
import AboutUsPage from "src/pages/home/AboutUsPage";
import ContactUsPage from "src/pages/home/ContactUsPage";
import AdminDataCountryCityList from "src/pages/admin/dataArea/AdminDataCountryCityList";

class NavigationPages {
  public MyPages: PageInterface[] = [
    // Core Pages
    { type: "basic",  key: "home",              route: RouteConfig.LINK.HOME,             component: HomePage, layout: "homeLayout" }, // This wont actually render the components, just the layout given how the home layout works
    { type: "basic",  key: "user-home",         route: RouteConfig.LINK.USER_HOME,        component: HomePage, layout: "userLayout" },
    { type: "basic",  key: "interpreter-home",  route: RouteConfig.LINK.INTERPRETER_HOME, component: HomePage, layout: "userLayout" },

    // Public Pages
    // These will be the services, onDemand, about us and contact us pages
    { type: "basic", key: "services",   route: RouteConfig.LINK.SERVICES,   component: ServicesPage,  layout: "publicLayout" },
    { type: "basic", key: "on-demand",  route: RouteConfig.LINK.ON_DEMAND,  component: OnDemandPage,  layout: "publicLayout" },
    { type: "basic", key: "about-us",   route: RouteConfig.LINK.ABOUT_US,   component: AboutUsPage,   layout: "publicLayout" },
    { type: "basic", key: "contact-us", route: RouteConfig.LINK.CONTACT_US, component: ContactUsPage, layout: "publicLayout" },

    // Login Pages
    { type: "basic", key: "login",                         route: RouteConfig.LINK.LOGIN,                          component: LoginPage,                       layout: "centeredLayout" },
    { type: "basic", key: "login-forgot-password",         route: RouteConfig.LINK.LOGIN_FORGOT_PASSWORD,          component: LoginForgotPasswordPage,         layout: "centeredLayout" },
    { type: "basic", key: "login-forgot-password-confirm", route: RouteConfig.LINK.LOGIN_FORGOT_PASSWORD_RESULT,   component: LoginForgotPasswordConfirmPage,  layout: "centeredLayout" },
    { type: "basic", key: "login-reset-password",          route: RouteConfig.LINK.LOGIN_RESET_PASSWORD,           component: LoginResetPasswordPage,          layout: "centeredLayout" },
    { type: "basic", key: "register",                      route: RouteConfig.LINK.REGISTER,                       component: RegisterPage,                    layout: "centeredLayout" },

    // Profile
    { type: "role", key: "profile-edit", route: RouteConfig.LINK.PROFILE_EDIT, layout: "userLayout", roles: {
      admin: EditProfilePage,
      moderator: EditProfilePage,
      interpreter: EditProfilePage,
      customer: EditProfilePage
    }},

    // User Facing Pages
    { type: "basic", key: "public-search-interpreter", route: RouteConfig.LINK.PUBLIC_SEARCH_INTERPRETER, component: InterpreterSearchPage,  layout: "publicLayout" },
    { type: "basic", key: "public-view-interpreter",   route: RouteConfig.LINK.PUBLIC_VIEW_INTERPRETER,   component: InterpreterProfilePage, layout: "publicLayout" },

    // New Appointment
    { type: "basic", key: "create-appointment",            route: RouteConfig.LINK.CREATE_APPOINTMENT,            component: CreateAppointmentPage,           layout: "publicLayout" },
    { type: "basic", key: "create-appointment-confirm",    route: RouteConfig.LINK.CREATE_APPOINTMENT_CONFIRM,    component: CreateAppointmentConfirmPage,    layout: "publicLayout" },
    { type: "basic", key: "create-appointment-processing", route: RouteConfig.LINK.CREATE_APPOINTMENT_PROCESSING, component: CreateAppointmentProcessingPage, layout: "publicLayout" },
    { type: "basic", key: "create-appointment-complete",   route: RouteConfig.LINK.CREATE_APPOINTMENT_COMPLETE,   component: CreateAppointmentCompletePage,   layout: "publicLayout" },

    // Admin Pages
    { type: "role", key: "admin-list-users",                  route: RouteConfig.LINK.ADMIN_LIST_USERS,                  layout: "userLayout", roles: { admin: AdminUsersListPage }},
    { type: "role", key: "admin-add-user",                    route: RouteConfig.LINK.ADMIN_USERS_ADD,                   layout: "userLayout", roles: { admin: AdminUsersAddPage  }},
    { type: "role", key: "admin-edit-user",                   route: RouteConfig.LINK.ADMIN_USERS_EDIT,                  layout: "userLayout", roles: { admin: AdminUsersEditPage }},
    { type: "role", key: "admin-data-area-service-list",      route: RouteConfig.LINK.ADMIN_DATA_AREA_SERVICE_LIST,      layout: "userLayout", roles: { admin: AdminDataServiceList }, },
    { type: "role", key: "admin-data-area-country-city-list", route: RouteConfig.LINK.ADMIN_DATA_AREA_COUNTRY_CITY_LIST, layout: "userLayout", roles: { admin: AdminDataCountryCityList }, },

    // Development Pages
    { type: "basic", key: "style-guide",             route: RouteConfig.LINK.STYLE_GUIDE,              layout: "userLayout",     component: StyleGuide },
    { type: "basic", key: "testing-user-home",       route: RouteConfig.LINK.TESTING_HOME,             layout: "devLayout",      component: DevelopmentTestingPage },
    { type: "basic", key: "testing-user-layout",     route: RouteConfig.LINK.TESTING_USER_LAYOUT,      layout: "userLayout",     component: DevelopmentTestingPage },
    { type: "basic", key: "testing-centered-layout", route: RouteConfig.LINK.TESTING_CENTERED_LAYOUT,  layout: "centeredLayout", component: DevelopmentTestingPage },
    { type: "basic", key: "testing-public-layout",   route: RouteConfig.LINK.TESTING_PUBLIC_LAYOUT,    layout: "publicLayout",   component: DevelopmentTestingPage },
  ];

  constructor() {
    // If we are running in dev, we really want to report key errors if the dev is paying attention
    if (process.env.NODE_ENV === "development") {
      const keys = this.MyPages.map(x => x.key);
      const keySet = new Set(keys[0]);

      keys.forEach(key => {
        const previousSize = keySet.size;
        keySet.add(key);
        if (keySet.size === previousSize) {
          console.error(`Warning! Duplicate key found in the ConfigurePages.ts file. Please revise this key: ${key}`);
        }
      });
    }
  }
}

const Pages = new NavigationPages();
export default Pages;
