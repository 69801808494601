import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Select, Skeleton, Switch, TimePicker, Tooltip } from "antd";
import moment from "moment-timezone";
import { useLocation, useNavigate } from "react-router";
import RouteConfig from "src/config/RouteConfig";
import { ClassNameHelper, NameOf, NotificationUtil } from "src/utils";
import UserController from "src/api/UserController";
import ServiceOfferingDTO from "src/models/generated/ServiceOfferingDTO";
import AppointmentController from "src/api/AppointmentController";
import TimeSlotDTO from "src/models/generated/TimeSlotDTO";
import SimpleUserProfileDTO from "src/models/generated/SimpleUserProfileDTO";
import { useQueryParam, useStateAsync } from "src/hooks";

interface CreateAppointmentFormData {
  interpreterId: string;
  service: string;
  date: moment.Moment;
  startTime: moment.Moment;
  // endTime: moment.Moment;
  duration: number;
}

const CreateAppointmentPage: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [Interpreters, setInterpreters, getInterpretersAsync] = useStateAsync<SimpleUserProfileDTO[]>([]);
  const [services, setServices] = useState<ServiceOfferingDTO[]>([]);
  const [availability, setAvailability] = useState<TimeSlotDTO[]>([]);

  const [submitting, setSubmitting] = useState(false);
  const [loadingInterpreters, setLoadingInterpreters] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingAvailability, setLoadingAvailability] = useState(false);

  // Query param values
  const [interpreterIdQueryParam] = useQueryParam("interpreterId");

  // Form watchers
  const selectedInterpreterId = Form.useWatch(NameOf<CreateAppointmentFormData>("interpreterId"), form);
  const selectedServiceId = Form.useWatch(NameOf<CreateAppointmentFormData>("service"), form);
  const selectedDate = Form.useWatch(NameOf<CreateAppointmentFormData>("date"), form);
  const selectedStartTime = Form.useWatch(NameOf<CreateAppointmentFormData>("startTime"), form);

  const availableStartTimes = availability
    .filter((slot) => {
      return slot.date!.isSame(moment(selectedDate), "day");
    })
    .map((slot) => {
      // Map the times to the select options
      return {
        label: slot.date!.format("hh:mm A"),
        value: slot.date!.format(),
      };
    });

  const availableTimeSlots = availability
    .filter((slot) => {
      return slot.date!.isSame(moment(selectedStartTime), "minute"); // This should be the same down to the minute
    })
    .flatMap((slot) => {
      // Each of the durations should be a time in minutes
      return slot.availableDurations!.map((duration) => {
        // Label should be the start time with duration in parenthesis
        return {
          label: `${slot.date!.clone().add(duration, "minute").format("hh:mm A")} (${duration} minutes)`,
          value: duration,
        };
      });
    });

  // Data fetchers
  const fetchInterpreters = async () => {
    setLoadingInterpreters(true);

    try {
      const interpretersResult = await UserController.getUserProfileById(interpreterIdQueryParam!);
      setInterpreters([interpretersResult.data]);
    } catch (error) {
      NotificationUtil.error({
        key: "CreateAppointmentPage",
        message: "Interpreters",
        description: "Error while fetching interpreters. Please refresh the page and try again",
      });
    }

    setLoadingInterpreters(false);
  };

  const fetchInterpreterServices = async (id: string) => {
    if (!id)
      return;

    setLoadingServices(true);

    try {
      const servicesResult = await AppointmentController.getInterpreterServices(id);
      setServices(servicesResult.data);
    } catch (error) {
      NotificationUtil.error({
        key: "CreateAppointmentPage",
        message: "Interpreter Services",
        description: "Error while fetching interpreter services. Please refresh the page and try again",
      });
    }

    setLoadingServices(false);
  };

  const fetchInterpreterAvailability = async (id: string, serviceId: string) => {
    if (!id || !serviceId)
    return;

    setLoadingAvailability(true);

    // Fetch the interpreter's available services from the userController
    try {
      const servicesResult = await AppointmentController.getInterpreterAvailability(id, serviceId, moment());
      setAvailability(servicesResult.data);
    } catch (error) {
      NotificationUtil.error({
        key: "CreateAppointmentPage",
        message: "Interpreter Availability",
        description: "Error while fetching interpreter availability. Please refresh the page and try again",
      });
    }

    setLoadingAvailability(false);
  };

  const onFinish = async (values: any) => {
    navigate(RouteConfig.CREATE_APPOINTMENT_CONFIRM("1"));

    try {
      setSubmitting(true);
      // const result = await AccountController.updateCurrentUserProfile(values);

      // Update the current profile data
      // await authContext.updateProfile(values);
      // setFormData(result.data);
      // form.resetFields();

      // NotificationUtil.success({
      //   key: "ProfilePage",
      //   message: "Profile",
      //   description: "Profile has been updated"
      // });
    } catch (error) {
      // NotificationUtil.error({
      //   key: "ProfilePage",
      //   message: "Profile",
      //   description: "Error while saving profile",
      // });
    } finally {
      setSubmitting(false);
    }
  };

  // OnMount
  useEffect(() => {
    const fetchPromise = fetchInterpreters();
    fetchPromise.then(async () => {
      const currentInterpreters = await getInterpretersAsync();
      if (currentInterpreters.length > 0) {
        // Set the interpreterId to the first interpreter
        // form.setFieldsValue({
        //   interpreterId: currentInterpreters[0].id,
        // });
      }
    });
  }, []);

  // Fetch interpreter services when the interpreter is selected
  useEffect(() => {
    // Fetch the interpreter's available services
    fetchInterpreterServices(selectedInterpreterId);
  }, [selectedInterpreterId]);

  // Fetch the interpreter's availability when the service is selected
  useEffect(() => {
    fetchInterpreterAvailability(selectedInterpreterId, selectedServiceId);
  }, [selectedServiceId]);

  return (
    <div className="standard-form-layout">
      <h1>Create Appointment</h1>
      <Form
        form={form}
        name="create_appointment_form"
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        className={ClassNameHelper.StandardFormStyle.toString()}
      >
        {/* Interpreter */}
        <Form.Item
          name={NameOf<CreateAppointmentFormData>("interpreterId")}
          label="Interpreter"
        >
          <Select
            allowClear
            loading={loadingInterpreters}
            options={Interpreters.map((interpreter) => ({ label: interpreter.displayName, value: interpreter.id }))}
          />
        </Form.Item>

        {/* Service */}
        <Form.Item
          name={NameOf<CreateAppointmentFormData>("service")}
          label="Service"
          rules={[{ required: true, message: "Please select a service" }]}
        >
          <Select
            loading={loadingServices}
            disabled={!selectedInterpreterId}
            options={services.map((service) => ({ label: service.serviceName, value: service.serviceId }))}
          />
        </Form.Item>

        {/* Date */}
        <Form.Item
          name={NameOf<CreateAppointmentFormData>("date")}
          label="Date"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker
            disabled={loadingAvailability || !selectedServiceId}
            showToday
            format={"MM/DD/YYYY"}
            disabledDate={(current) => {
              if (!current || current.isBefore(moment(), "day"))
                return false;

              // Look over our availability
              // If there is a slot that matches the current date, return true
              // Otherwise, return false
              return !availability.some((slot) => {
                return slot.date?.isSame(current, "day") ?? false;
              });
            }}
          />
        </Form.Item>

        {/* Start Time */}
        <Form.Item
          name={NameOf<CreateAppointmentFormData>("startTime")}
          label="Start Time"
          rules={[{ required: true, message: "Please select a start time" }]}
        >
          <Select
          disabled={!selectedStartTime}
          options={availableStartTimes} />
        </Form.Item>

        {/* Durations */}
        <Form.Item
          name={NameOf<CreateAppointmentFormData>("duration")}
          label="Duration"
          rules={[{ required: true, message: "Please select a duration" }]}
        >
          <Select
          disabled={!selectedStartTime}
          options={availableTimeSlots} />
        </Form.Item>

        {/* Confirm */}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateAppointmentPage;
