import "./ClassNameHelper.less";

/**
 * The goal of this class is to have a list of classnames in a semi-centralized location for my own sanity.
 */
class ClassNameHelper {
  value: string = "";

  constructor(previous?: string) {
    this.value = previous || "";
  }

  /** Adds a custom class to the chain */
  Add(eh: string) {
    return new ClassNameHelper(`${this.value} ${eh}`);
  }

  toString() {
      return this.value.trim();
  }

  /** Designed for use directly on the form component */
  get StandardFormStyle() { return this.Add("standard-form-style"); }


}

export default new ClassNameHelper();
