import React, { useEffect } from "react";
import { Modal, Form, Input, Select, InputNumber, Button, Switch, Tooltip } from "antd";

import { NameOf, ObjectUtil } from "src/utils";
import AdminCityRequest from "src/models/generated/AdminCityRequest";
import { QuestionCircleOutlined } from "@ant-design/icons";
import CountryDTO from "src/models/generated/CountryDTO";
import AdminCountryCityDTO from "src/models/generated/AdminCountryCityDTO";

interface AdminDataCityModalProps {
  open: boolean;
  countries: AdminCountryCityDTO[];
  value?: AdminCityRequest;
  loading?: boolean;
  onCancel: () => void;
  onFinished: (value: AdminCityRequest) => void;
}

const AdminDataCityModal: React.FC<AdminDataCityModalProps> = (props) => {
  const [form] = Form.useForm();

  const handleFinished = (value: AdminCityRequest) => {
    if (props.value != null)
      value = { ...value, id: props.value.id };
    props.onFinished?.(ObjectUtil.TrimValues(value));
  };

  useEffect(() => {
    if (props.value == null) {
      form.resetFields();
      return;
    }
    form.setFieldsValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!props.open) {
      form.resetFields();
    }
  }, [props.open]);

  console.log("AdminDataCityModal", props.countries);

  return (
    <Modal
      open={props.open}
      title={props.value?.id != null ? "Edit Service" : "Create Service"}
      confirmLoading={props.loading}
      onCancel={props.onCancel}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleFinished}
        labelCol={{ flex: "0 0 110px" }}
      >
        {/* Name */}
        <Form.Item
          label="Name"
          name={NameOf<AdminCityRequest>("name")}
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>

        {/* Approved */}
        <Form.Item
          label={
            <div>
              <span>Approved</span>
              <Tooltip title="Approved cities are allowed to be used and shown to users as well searched with on the homepage">
                <QuestionCircleOutlined style={{ marginLeft: 8 }} />
              </Tooltip>
            </div>
          }
          name={NameOf<AdminCityRequest>("isAdminApproved")}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        {/* Country */}
        <Form.Item
          label="Country"
          name={NameOf<AdminCityRequest>("countryId")}
        >
          <Select
            options={props.countries.map((c) => ({ label: c.countryName, value: c.countryId }))}
          />
        </Form.Item>

        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminDataCityModal;
