// This will be a standard contact us page with just a phone number and email address for now
// We can add a form later if we want

import React, { useEffect, useState } from "react";

const OnDemandPage: React.FC = () => {
  return (
    <div>
        <h1>On Demand</h1>
        <p>
          Under Construction, please check back soon!
        </p>
    </div>
  );
};

export default OnDemandPage;
