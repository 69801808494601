import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Skeleton } from "antd";
import { ConstantsUtil, NameOf, StringUtil } from "src/utils";
import UserProfileGeneralDTO from "src/models/generated/UserProfileGeneralDTO";
import UserProfileDTO from "src/models/generated/UserProfileDTO";

interface GeneralFormData extends UserProfileDTO {}

interface GeneralProps {}

const GeneralTab: React.FC<GeneralProps> = (props) => {
  // Dev Note: Use this to get the form since we are child of the tabs
  const form = Form.useFormInstance<GeneralFormData>();

  return (
    <>
      {/* First Name */}
      <Form.Item
        label="First Name"
        name={[NameOf<GeneralFormData>("general"), NameOf<GeneralFormData["general"]>("firstName")]}
        rules={[{ required: true, message: "First Name is required" }]}
      >
        <Input allowClear />
      </Form.Item>

      {/* Middle Name */}
      <Form.Item
        label="Middle Name"
        name={[NameOf<GeneralFormData>("general"), NameOf<GeneralFormData["general"]>("middleName")]}
      >
        <Input allowClear />
      </Form.Item>

      {/* Last Name */}
      <Form.Item
        label="Last Name"
        name={[NameOf<GeneralFormData>("general"), NameOf<GeneralFormData["general"]>("lastName")]}
        rules={[{ required: true, message: "Last Name is required" }]}
      >
        <Input allowClear />
      </Form.Item>

      {/* Gender */}
      <Form.Item
        label="Gender"
        name={[NameOf<GeneralFormData>("general"), NameOf<GeneralFormData["general"]>("gender")]}
        // rules={[{ required: true, message: "Gender is required" }]}
      >
        <Select
          allowClear
          options={ConstantsUtil.genders.map((x) => ({
            value: x.key,
            label: x.label,
          }))}
        />
      </Form.Item>

      {/* Email address */}
      <Form.Item
        label="Email Address"
        name={[NameOf<GeneralFormData>("general"), NameOf<GeneralFormData["general"]>("emailAddress")]}
      >
        <Input disabled />
      </Form.Item>

      {/* Phone Number */}
      <Form.Item
        label="Phone Number"
        name={[NameOf<GeneralFormData>("general"), NameOf<GeneralFormData["general"]>("phoneNumber")]}
      >
        <Input />
      </Form.Item>

      {/* Date of Birth */}
      <Form.Item
        label="Date of Birth"
        name={[NameOf<GeneralFormData>("general"), NameOf<GeneralFormData["general"]>("dateOfBirth")]}
        rules={[{ required: true, message: "Date of Birth is required" }]}
      >
        <DatePicker />
      </Form.Item>
    </>
  );
};

export default GeneralTab;
