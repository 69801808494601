import React, { useContext, useState } from "react";
import { Col, Grid, Layout, Menu, MenuProps, Row } from "antd";
import { CommentOutlined, HomeOutlined, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import "./UserLayout.less";
import { NavLink } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import StandardHeader from "./headers/StandardHeader";
import StandardFooter from "./footers/StandardFooter";

const { Content, Footer, Sider } = Layout;

const UserLayout: React.FC<React.PropsWithChildren> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [collapsed, setCollapsed] = useState(false);
  const breakpoint = Grid.useBreakpoint();

  const isDesktop = breakpoint.lg || breakpoint.xl || breakpoint.xxl;
  const isMobile = breakpoint.xs || breakpoint.sm || breakpoint.md;

  let menuItems: Required<MenuProps["items"]> = [
    { key: "1", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Home</NavLink>, icon: <HomeOutlined /> },
  ];

  // Dev Note: I think that, to get the default selectedKeys working, I will need to move the menuItems to a different object, one where I have access to the URL, and then build it as react components

  // TODO: Make this a constant somewhere
  switch (authContext.auth?.roleId) {
    case 1:
      // Admin
      menuItems.push({
        key: "2",
        label: <NavLink to={{ pathname: RouteConfig.LINK.ADMIN_LIST_USERS }}>Users</NavLink>,
        icon: <UsergroupAddOutlined />,
      });
      menuItems.push({
        key: "3",
        label: <NavLink to={{ pathname: RouteConfig.LINK.PROFILE_EDIT }}>Profile</NavLink>,
        icon: <UserOutlined />,
      });

      // Admin menu items for the second menu
      menuItems.push({ key: "divider", type: "divider" });
      menuItems.push({
        key: "11",
        danger: true,
        label: <NavLink to={{ pathname: RouteConfig.LINK.ADMIN_DATA_AREA_SERVICE_LIST }}>Services</NavLink>,
        icon: <CommentOutlined />,
      });
      menuItems.push({
        key: "12",
        danger: true,
        label: <NavLink to={{ pathname: RouteConfig.LINK.ADMIN_DATA_AREA_COUNTRY_CITY_LIST }}>Country & City</NavLink>,
        icon: <CommentOutlined />,
      });
      break;
    case 2:
      // Moderator
      menuItems.push({
        key: "2",
        label: <NavLink to={{ pathname: RouteConfig.LINK.PROFILE_EDIT }}>Profile</NavLink>,
        icon: <UserOutlined />,
      });
      break;
    case 3:
      // Interpreter
      menuItems.push({
        key: "2",
        label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Appointments</NavLink>,
        icon: <UserOutlined />,
      });
      menuItems.push({
        key: "3",
        label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Schedule</NavLink>,
        icon: <UserOutlined />,
      });
      menuItems.push({
        key: "4",
        label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Profile</NavLink>,
        icon: <UserOutlined />,
      });
      break;
    case 4:
      // Customer
      menuItems.push({
        key: "2",
        label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Profile</NavLink>,
        icon: <UserOutlined />,
      });
      // This will be the base page for the appointments
      menuItems.push({
        key: "3",
        label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Appointment</NavLink>,
        icon: <UserOutlined />,
        children: [
          {
            key: "31",
            label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>New Appointment</NavLink>,
            icon: <UserOutlined />,
          },
          {
            key: "32",
            label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Edit Appointment</NavLink>,
            icon: <UserOutlined />,
          },
        ],
      });
      menuItems.push({
        key: "4",
        label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Search Interpreters</NavLink>,
        icon: <UserOutlined />,
      });
      break;
    default:
      menuItems.push({
        key: "2",
        label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Profile</NavLink>,
        icon: <UserOutlined />,
      });
      break;
  }

  // I feel like the whole layout might need to be changed if the user is on mobile
  // I reviewed several websites and, apart from them looking way too damn close to each other, they all keep the header and footer
  //  With the menu either being a standard menu on the top or a drawer on the side
  // Since we have a sidebar, we need to show just a drawer when on mobile ~nya
  if (isMobile && !isDesktop) {
    return (
      <Layout className="user-layout-mobile">
        <StandardHeader menuItems={menuItems} header="user" mobileHeader="mobile" />

        <Content className="content-container">
          <Content>{props.children}</Content>
          <StandardFooter />
        </Content>
      </Layout>
    );
  }

  return (
    <Layout className="user-layout">
      <Sider className="layout-sider" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" />
        <Menu className="layout-sider-menu" theme="dark" defaultSelectedKeys={["1"]} mode="inline" items={menuItems} />
      </Sider>

      <Content>
        <Layout className="content-layout">
          <StandardHeader menuItems={menuItems} header="user" mobileHeader="mobile">
            {/* Show only for admins because this is mainly just for me */}
            {authContext.auth?.roleId === 1 && (
              <Row justify="end">
                <Col>
                  <Row style={{ fontSize: 20, fontWeight: "bold", width: 150 }}>
                    <Col style={{ marginRight: 8 }}>Layout:</Col>
                    <Col xs={12} sm={0}>
                      XS
                    </Col>
                    <Col xs={0} sm={12} md={0}>
                      SM
                    </Col>
                    <Col xs={0} md={12} lg={0}>
                      MD
                    </Col>
                    <Col xs={0} lg={12} xl={0}>
                      LG
                    </Col>
                    <Col xs={0} xl={12} xxl={0}>
                      XL
                    </Col>
                    <Col xs={0} xxl={12}>
                      XXL
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </StandardHeader>

          <Content className="content-container">
            {props.children}
            <StandardFooter />
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default UserLayout;
