import { Menu, MenuProps, Layout, Row, Col } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import lowerLogo from "src/assets/site-logo.png";
import "./PublicHeader.less";

interface PublicHeaderProps {
  menuItems: MenuProps["items"];
}

const { Header } = Layout;

const PublicHeader: React.FC<React.PropsWithChildren<PublicHeaderProps>> = ({menuItems}) => {
  return (
    <Header className='public-header'>

      <Row align="middle" justify="space-between">
        <Col flex="64px" style={{ display: "flex" }}>
          <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>
            <div className="site-logo-container">
              <img src={lowerLogo} alt="Site Logo" />
            </div>
          </NavLink>
        </Col>
        <Col flex="1">
          <Menu
            // theme="dark"
            mode="horizontal"
            items={menuItems}
          />
        </Col>
      </Row>
    </Header>
  );
};

export default PublicHeader;
