import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, Form, Image, Input, message, Modal, Select, Skeleton, Switch, Tabs, Tooltip, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { NameOf, StringUtil } from "src/utils";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import NotificationUtil from "src/utils/NotificationUtil";
import UserProfileDTO from "src/models/generated/UserProfileDTO";
import { PasswordResetModal } from "src/components/core/passwordResetModal";
import PhoneVerificationModal from "src/components/core/PhoneVerificationModal";
import AccountController from "src/api/AccountController";
import ProfileController from "src/api/ProfileController";
import { UploadFile } from "antd/lib/upload/interface";
import UploadMediaDTO from "src/models/generated/UploadMediaDTO";
import imageNotFound from "src/assets/core/image-not-found.png";
import GeneralTab from "./GeneralTab";
import ExpertiseTab from "./ExpertiseTab";
import ScheduleTab from "./ScheduleTab";
import UserProfileRequest from "src/models/generated/UserProfileRequest";

interface ProfileHomeFormData extends UserProfileDTO {
}

const EditProfilePage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<ProfileHomeFormData>();
  const [profileData, setProfileData] = useState<UserProfileDTO>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    setLoading(true);
    const profileResult = await AccountController.getCurrentUserProfile();

    // Set the form directly from here. Setting an initial value on the form object will not allow us to reset later
    form.setFieldsValue(profileResult.data);

    setProfileData(profileResult.data);
    setLoading(false);
  };

  const handleFormSave = async (values: UserProfileDTO | Partial<UserProfileDTO> | UserProfileRequest | Partial<UserProfileRequest>) => {
    setSubmitting(true);
    try {
      const result = await AccountController.updateCurrentUserProfile(values as UserProfileRequest);

      // Update the current profile data
      await authContext.updateProfile(result.data);
      setProfileData(result.data);
      form.setFields(Object.entries(result.data).map(([key, value]) => ({ name: key, value: value })));

      NotificationUtil.success({
        key: "EditProfilePage",
        message: "Profile",
        description: "Profile has been updated",
      });
    } catch (error) {
      NotificationUtil.error({
        key: "EditProfilePage",
        message: "Profile",
        description: "Error while saving profile",
        error,
      });
    }
    setSubmitting(false);
  };

  return (
    <div className="profile-home">
      <h1>User Profile</h1>

      <Image
        style={{ aspectRatio: "4 / 5", objectFit: "cover", width: 200 }}
        preview={false}
        src={StringUtil.IsNullOrEmpty(profileData?.general.profileUrl) ? "error" : profileData?.general.profileUrl}
        fallback={imageNotFound}
      />

      {/* Start the form here */}
      <Form
        form={form}
        layout="vertical"
        style={{ marginTop: "20px" }}
        // labelCol={{ flex: "150px" }}
        // We might also consider just a static form wrapper width of like 400 instead of "responsive"
        wrapperCol={{ sm: 18, md: 14, lg: 12, xl: 10, xxl: 8 }}
        onFinish={handleFormSave}
      >
        {/* Save Button here */}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Save All
          </Button>
        </Form.Item>

        <Skeleton loading={loading}>
          <Tabs defaultActiveKey="1" items={[
            { key: "1", label: "General", children: <GeneralTab /> },
            { key: "2", label: "Skills and Services", children: <ExpertiseTab /> },
            { key: "3", label: "Schedule", children: <ScheduleTab /> },
          ]} />
        </Skeleton>
      </Form>
    </div>
  );
};

export default EditProfilePage;
