import React, { useState } from "react";
import { Menu, MenuProps, Layout, Row, Col, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import lowerLogo from "src/assets/site-logo.png";
import "./MobileHeader.less";

interface MobileHeaderProps {
  menuItems: MenuProps["items"];
}

const { Header } = Layout;

const MobileHeader: React.FC<React.PropsWithChildren<MobileHeaderProps>> = ({ menuItems }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Drawer
        title="Navigation"
        placement="right"
        closable={true}
        open={drawerOpen}
        width={300}
        onClose={() => setDrawerOpen(false)}
      >
        <Menu style={{ fontSize: 20 }} mode="inline" items={menuItems} theme="dark" />
      </Drawer>

      <Header className="mobile-header">
        <Row align="middle" justify="space-between">
          <Col flex="64px" style={{ display: "flex" }}>
            <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>
              <div className="site-logo-container">
                <img src={lowerLogo} alt="Site Logo" />
              </div>
            </NavLink>
          </Col>
          <Col flex="1">
            <span
              style={{
                fontSize: 24,
                marginLeft: 12,
                lineHeight: "30px",
                wordBreak: "break-word",
                height: 32,
                display: "block",
              }}
            >
              Interpreters World
            </span>
          </Col>
          <Col flex="64px">
            <Button
              type="ghost"
              style={{ color: "white", fontSize: 20, lineHeight: "24px", padding: "10px 16px 8px", height: "unset" }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <MenuOutlined />
            </Button>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default MobileHeader;
